import React from "react";
import {
  UpRightArrowIcon,
  TrashIcon,
  EditIcon,
  CreateIcon,
} from "../iconComponent/Icon";
import { Link, useLocation } from "react-router-dom";
import Spinner from "./Spinner";
import { useUserContext } from "../../context";
import { format } from "date-fns";

export default function DataTable({ data, handleDelete, handlePaid }) {
  const location = useLocation();
  const approved = location.pathname.split("/")[2];
  const invoice = location.pathname.split("/")[1];
  const rejected = location.pathname.split("/")[2];
  const timesheets = location.pathname.split("/")[1];

  const { user } = useUserContext();

  return (
    <div className="flex w-full flex-col rounded-lg overflow-x-auto">
      <div className=" w-full">
        <div className=" pb-4 ">
          <div className=" rounded-lg  ">
            <table
              className="table-auto text-left text-sm font-light w-full"
              id="table-to-xls"
            >
              <thead className="border-b bg-[#E3E8EF] font-medium rounded-lg dark:border-neutral-500">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                  >
                    Contractor Name
                  </th>
                  {invoice === "invoice-reports" ? (
                    <>
                      <th
                        scope="col"
                        className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                      >
                        Client Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                      >
                        Created on
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                      >
                        Client Rate
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                      >
                        Total
                      </th>
                    </>
                  ) : null}
                  <th
                    scope="col"
                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                  >
                    Timesheet name
                  </th>
                  {invoice !== "invoice-reports" ? (
                    <>
                      <th
                        scope="col"
                        className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                      >
                        {rejected === "rejected"
                          ? "Rejected Date"
                          : "Approval Date"}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                      >
                        Created date
                      </th>
                    </>
                  ) : null}

                  <th
                    scope="col"
                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                  >
                    Actions
                  </th>

                  {invoice === "invoice-reports" ? (
                    <>
                      <th
                        scope="col"
                        className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                      >
                        Marked as Paid
                      </th>
                    </>
                  ) : null}

                  {rejected === "rejected" ? (
                    <>
                      <th
                        scope="col"
                        className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                      >
                        Resaon of Rejection
                      </th>
                    </>
                  ) : null}
                </tr>
              </thead>

              {data ? (
                <>
                  {data?.map((obj, index) => (
                    <tbody key={obj?._id} className="w-full">
                      <>
                        <tr className="border-b dark:border-neutral-500">
                          <td
                            width="20%"
                            className="whitespace-nowrap p-3 text-center "
                          >
                            {obj?.name ? obj?.name : "no name"}
                          </td>
                          {invoice === "invoice-reports" ? (
                            <>
                              <td
                                width="20%"
                                className="whitespace-nowrap p-3 text-center "
                              >
                                {obj?.clientName &&
                                obj.clientName !== "undefined"
                                  ? obj?.clientName
                                  : "no client Name"}
                              </td>
                              <td
                                width="20%"
                                className="whitespace-nowrap p-3 text-center "
                              >
                                {obj?.sharedDate
                                  ? obj?.sharedDate
                                  : "00/00/0000"}
                              </td>
                              <td
                                width="20%"
                                className="whitespace-nowrap p-3 text-center "
                              >
                                {obj?.clientRate &&
                                obj.clientRate !== "undefined"
                                  ? obj?.clientRate
                                  : "$0"}
                              </td>
                              <td
                                width="20%"
                                className="whitespace-nowrap p-3 text-center "
                              >
                                {obj?.total ? `$${obj?.total}` : "Not yet "}
                              </td>
                            </>
                          ) : null}

                          <td className="whitespace-nowrap text-center ">
                            {obj?.timeSheetName ? obj.timeSheetName : "no name"}
                          </td>
                          {invoice !== "invoice-reports" ? (
                            <>
                              <td className="whitespace-nowrap text-center ">
                                {obj?.approvalDate
                                  ? obj.approvalDate
                                  : "Not yet"}
                              </td>
                              <td className="whitespace-nowrap text-center ">
                                {obj?.createdAt
                                  ? format(
                                      new Date(obj?.createdAt),
                                      "MM/dd/yyyy"
                                    )
                                  : ""}
                              </td>
                            </>
                          ) : null}

                          <td className="whitespace-nowrap text-center ">
                            {obj?.status ? obj.status : "no status"}
                          </td>
                          <td className="whitespace-nowrap px-2 text-center ">
                            <div className="flex justify-center items-center gap-x-2">
                              {invoice === "invoice-reports" ||
                              timesheets === "timesheets-reports" ||
                              (obj?.status === "Approved" &&
                                user?.role === "Contractor") ? null : (
                                <>
                                  <Link to={`/view-time-sheets/${obj?._id}`}>
                                    <button className="mt-1">
                                      <EditIcon width={20} height={20} />
                                    </button>
                                  </Link>
                                </>
                              )}
                              {obj?.status === "Approved" &&
                              user?.role === "Contractor" ? null : (
                                <>
                                  {invoice === "invoice-reports" ||
                                  timesheets === "timesheets-reports" ? null : (
                                    <button
                                      onClick={() => handleDelete(obj?._id)}
                                    >
                                      <TrashIcon width={20} height={20} />
                                    </button>
                                  )}
                                </>
                              )}

                              {invoice === "invoice-reports" ? (
                                <Link to={`/timesheets/${obj?.timeSheetId}`}>
                                  <button className="bg-black rounded-sm  text-white h-8 w-20 flex justify-center items-center">
                                    <p>View</p>
                                    <UpRightArrowIcon />
                                  </button>
                                </Link>
                              ) : (
                                <Link to={`/timesheets/${obj?._id}`}>
                                  <button className="bg-black rounded-sm  text-white h-8 w-20 flex justify-center items-center">
                                    <p>View</p>
                                    <UpRightArrowIcon />
                                  </button>
                                </Link>
                              )}

                              {approved === "approved" &&
                              user?.role === "Admin" ? (
                                <>
                                  <Link to={`/generate-invoice/${obj._id}`}>
                                    <button className="bg-black rounded-sm  text-white h-8 w-auto px-2 py-3 flex justify-center items-center">
                                      <p>Generate invoice</p>
                                      <UpRightArrowIcon />
                                    </button>
                                  </Link>
                                </>
                              ) : null}
                            </div>
                          </td>
                          {rejected === "rejected" ? (
                            <>
                              <td className="whitespace-nowrap text-center ">
                                {obj?.desc ? obj.desc : "no description"}
                              </td>
                            </>
                          ) : null}

                          <td className="whitespace-nowrap text-center ">
                            <div className="flex justify-center items-center">
                              {invoice === "invoice-reports" ? (
                                <button
                                  disabled={
                                    obj?.status === "paid" ? true : false
                                  }
                                  onClick={() => handlePaid(obj?._id)}
                                  className={`${
                                    obj?.status === "paid"
                                      ? "bg-green-500"
                                      : "bg-black"
                                  }  rounded-sm  text-white h-8 w-14 flex justify-center items-center`}
                                >
                                  <CreateIcon />
                                </button>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      </>
                    </tbody>
                  ))}
                </>
              ) : (
                <div className="h-14 flex justify-center items-center">
                  <Spinner />
                </div>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
