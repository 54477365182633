import React from "react";
import cancel from "../../assests/cancelIcon.svg";
import create from "../../assests/createTick.svg";
import { addEvent } from "../../Api";
import { useUserContext } from "../../context";
import Swal from "sweetalert2";

export default function AddEventModal({ showModal, setShowModal }) {
  const { user } = useUserContext();

  async function handleSubmit(event) {
    event.preventDefault();
    const title = event.target.title.value;
    const start = event.target.start.value;
    const end = event.target.end.value;
    const color = event.target.eventDay.value;
    const desc = event.target.desc.value;

    if (title && start && end && color && desc) {
      const newEvent = {
        user: `${user?.userId}`,
        title,
        start,
        end,
        color,
        desc,
      };
      try {
        const res = await addEvent(newEvent);
        if (res) {
          Swal.fire({
            width: "20em",
            height: "20em",
            position: "center",
            icon: "success",
            title: `${res?.data?.message}`,
            showConfirmButton: false,
            timer: 1000,
          });
          window.location.reload();
          setShowModal(false);
        }
      } catch (error) {
        console.log(error?.message);
      }
    } else {
      Swal.fire({
        width: "20em",
        height: "20em",
        position: "center",
        icon: "error",
        text: "Please fill empty fields",
      });
    }
  }

  return (
    <>
      {showModal ? (
        <>
          <div className=" overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative lg:w-[538px] md:w-[400px] my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none pb-5">
                {/*header*/}
                <div className="flex items-start justify-between p-5">
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <img src={cancel} alt="cancel" />
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-6 flex-auto">
                  <h1 className="font-medium font-lg">Add Event</h1>

                  <form onSubmit={handleSubmit} className="flex flex-col">
                    <label htmlFor="title" className="text-[#7A7A79] mt-4">
                      Name of Event
                    </label>
                    <input
                      className="border border-1 border-[#B8B7B6] rounded h-[35px] pl-2 outline-none "
                      name="title"
                      placeholder="Enter name of event"
                    />
                    <fieldset className="flex">
                      <div className="flex items-center justify-center mt-4">
                        <input
                          type="radio"
                          id="blue"
                          name="eventDay"
                          value="blue"
                        />
                        <label htmlFor="blue">
                          <span className="ml-2 text-[#7A7A79]">Holiday</span>
                        </label>
                      </div>
                      <div className="flex items-center justify-center mt-4 ml-5">
                        <input
                          type="radio"
                          id="red"
                          name="eventDay"
                          value="red"
                        />
                        <label htmlFor="red">
                          <span className="ml-2 text-[#7A7A79]">
                            Public Holiday
                          </span>
                        </label>
                      </div>
                    </fieldset>

                    <label htmlFor="start" className="text-[#7A7A79] mt-4">
                      Start of Event
                    </label>
                    <input
                      className="border border-1 border-[#B8B7B6] rounded h-[35px] pl-2 outline-none"
                      name="start"
                      type="date"
                    />
                    <label htmlFor="end" className="text-[#7A7A79] mt-4">
                      End of Event
                    </label>
                    <input
                      className="border border-1 border-[#B8B7B6] rounded h-[35px] pl-2 outline-none"
                      name="end"
                      type="date"
                    />
                    <label htmlFor="end" className="text-[#7A7A79] mt-4">
                      Event Description
                    </label>
                    <input
                      className="border border-1 border-[#B8B7B6] rounded h-[35px] pl-2 outline-none"
                      name="desc"
                      type="text"
                      placeholder="Enter event description"
                    />
                    <div className="flex justify-between gap-4 py-3">
                      <button
                        type="button"
                        className="font-normal text-sm w-full h-[44px] mt-3 border border-gray-500 rounded"
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex items-center justify-center font-normal text-sm h-[44px] w-full bg-black  mt-3 text-white rounded"
                      >
                        <img src={create} alt="create" />
                        <p className="ml-2">Save Event</p>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
