import React from "react";
import Calender from "./Calender";
import Holidays from "./Holidays";
import TeamBirthDay from "./TeamBirthDay";
import { useUserContext } from "../../context";

export default function CalenderPage() {
  const { user } = useUserContext();
  return (
    <div className="w-full">
      <div className="flex items-center px-4 lg:justify-between lg:flex-row flex-col md:justify-between md:flex-row  justify-center my-2">
        <div className="w-full border-b-2 pb-3">
          <h1 className="font-semibold text-lg lg:text-left md:text-left text-center">
            Calendar
          </h1>
          <p className="text-base w-full font-normal text-gray-500 lg:text-left md:text-left text-center">
            Add and view events and holidays
          </p>
        </div>
      </div>

      <Calender />
      <div className="flex md:flex-row flex-col xl:gap-x-10 lg:gap-x-5 ">
        <Holidays />
        {user?.role === "Admin" || user?.role === "Super Admin" ? (
          <TeamBirthDay />
        ) : null}
      </div>
    </div>
  );
}
