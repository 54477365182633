import React from "react";
import { useNavigate } from "react-router-dom";
import { reset_password } from "../../Api";
import { Formik, Field, Form } from "formik";
import { useUserContext } from "../../context";
import Swal from "sweetalert2";
export default function ResetPassword() {
  const { user } = useUserContext();
  const navigate = useNavigate();
  return (
    <div className="w-full md:px-5 md:mt-0 mt-5">
      <div className="mt-4 text-[14px] text-[#666666] ">
        <Formik
          initialValues={{
            oldPassword: "",
            new_password: "",
          }}
          onSubmit={async (values) => {
            try {
              const res = await reset_password(user?.userId, values);
              if (res?.data?.success) {
                navigate("/home");
                Swal.fire({
                  width: "20em",
                  height: "20em",
                  position: "center",
                  icon: "success",
                  title: `${res?.data?.message}`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } catch (error) {
              console.log(error);
              Swal.fire({
                width: "20em",
                height: "20em",
                title: `${error?.response?.data?.message}`,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
            }
          }}
          enableReinitialize
        >
          {({ isSubmitting, errors, touched, values }) => (
            <Form>
              <h1 className="text-lg font-semibold md:px-0 px-9">
                Reset Password
              </h1>
              <div className="flex lg:flex-row md:flex-row flex-col gap-x-8 lg:px-0 md:px-0 px-9">
                <div className="flex w-full flex-col py-3">
                  <label htmlFor="oldPassword" className="text-[#7A7A79]">
                    Old Password
                  </label>
                  <Field
                    name="oldPassword"
                    type="password"
                    placeholder="Enter old password"
                    className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                      touched.licensekey && errors.licensekey
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                </div>

                <div className="flex w-full flex-col py-3">
                  <label htmlFor="new_password" className="text-[#7A7A79]">
                    New Password
                  </label>
                  <Field
                    name="new_password"
                    type="password"
                    placeholder="Enter new password"
                    className={`border text-[#11141C] border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                      touched.new_password && errors.new_password
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                </div>
              </div>
              <div className="flex justify-center gap-x-5 items-end  h-14 rounded-lg">
                <button
                  type="button"
                  className="w-[88px] h-[35px] rounded-md border text-xs font-normal"
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-[102px] h-[35px] rounded-md border text-white bg-black  text-xs font-normal"
                >
                  Save Changes
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
