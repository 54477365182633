import React, { useEffect, useState } from "react";
import cancel from "../../assests/cancelIcon.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import create from "../../assests/createTick.svg";
import {
  findUserById,
  EditUserById,
  allContractors,
  editContractor,
} from "../../Api";
import * as Yup from "yup";
import Swal from "sweetalert2";
import EditPassword from "./EditPassword";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const EIGHTEEN_YEARS_IN_MS = 18 * 365.25 * 24 * 60 * 60 * 1000; // accounting for leap years
const minimumDate = new Date(1900, 0, 1); // January 1, 1900

const contractorSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Name can only contain letters and spaces")
    .label("name")
    .nullable(),
  email: Yup.string().matches(emailRegex, "Invalid email format").nullable(),
  DOB: Yup.date()
    .max(
      new Date(Date.now() - EIGHTEEN_YEARS_IN_MS),
      "You must be at least 18 years"
    )
    .min(minimumDate, "Date of birth cannot be before January 1, 1900")
    .nullable(),
  shore: Yup.string().nullable(),
  contractorRate: Yup.number()
    .typeError("Please enter a valid number")
    .nullable(),
  identificationNumber: Yup.number()
    .typeError("Must be a number") // Throw an error if the input is not a number
    .test("len", "Must be exactly 9 digits", (val) => {
      if (val === null || val === undefined) return true; // Allow null or undefined
      return String(val).length <= 9; // Ensure length is 9
    })
    .nullable(),
  socialSecurityNumber: Yup.number()
    .typeError("Must be a number") // Throw an error if the input is not a number
    .test("len", "Must be exactly 9 digits", (val) => {
      if (val === null || val === undefined) return true; // Allow null or undefined
      return String(val).length <= 9; // Ensure length is 9
    })
    .nullable(),
  jobTitle: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Job title can only contain letters and spaces")
    .nullable(),
  joiningDate: Yup.date()
    .label("Joining date")
    .max(new Date(), "Joining date must be in the past")
    .nullable(),
  totalYearExperince: Yup.string()
    .matches(/^\d*$/, "Only numbers are allowed")
    .nullable(),
  guddgeEmailPlan: Yup.string()
    .matches(emailRegex, "Invalid email format")
    .nullable(),
});
export default function EditAdminModal({ editModal, setEditModal, id }) {
  const [data, setData] = useState();
  const [cont, setCont] = useState();
  const [file, setFile] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getSingleUserData();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    getAllContractor();
  }, []);
  const getSingleUserData = async () => {
    const res = await findUserById(id);
    if (res?.data?.success) {
      setData(res?.data?.user);
    }
  };
  const getAllContractor = async () => {
    const res = await allContractors();
    if (res?.data?.success) {
      setCont(res?.data?.contractors);
    }
  };
  const handlefile = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFormData = (values) => {
    var formData = new FormData();
    formData.append("name", values?.name);
    if (!!file) formData.append("agreement", file);
    formData.append("email", values?.email);
    formData.append("DOB", values?.DOB);
    formData.append("phoneNumber", values?.phoneNumber);
    formData.append("jobTitle", values?.jobTitle);
    formData.append("joiningDate", values?.joiningDate);
    formData.append("totalYearExperince", values?.totalYearExperince);
    formData.append("guddgeEmailPlan", values?.guddgeEmailPlan);
    formData.append("agreementEndDate", values?.agreementEndDate);
    formData.append("shore", values?.shore);
    formData.append("contractorRate", values?.contractorRate);
    formData.append("companyName", values?.companyName);
    formData.append("identificationNumber", values?.identificationNumber);
    formData.append("socialSecurityNumber", values?.socialSecurityNumber);
    formData.append("mailingAddress", values?.mailingAddress);
    formData.append("alternativeEmailAdress", values?.alternativeEmailAdress);
    return formData;
  };
  return (
    <>
      {editModal ? (
        <>
          <div className="hide-scrollbar overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative lg:w-[538px] md:w-[400px] my-6 mx-auto max-w-3xl px-5">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none pb-5">
                {/*header*/}
                <div className="flex items-start justify-between p-5">
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setEditModal(false)}
                  >
                    <img src={cancel} alt="cancel" />
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-6 flex-auto">
                  <div>
                    <h1 className="font-medium font-lg">Edit {data?.role}</h1>
                    <p className="text-gray-500 text-base">
                      You are creating role to this project
                    </p>
                  </div>
                  <div className="mt-4 text-[14px] text-[#666666] ">
                    <Formik
                      validationSchema={contractorSchema}
                      initialValues={{
                        name: `${data?.name ? data?.name : ""}`,
                        email: `${data?.email ? data?.email : ""}`,
                        phoneNumber: `${
                          data?.phoneNumber ? data?.phoneNumber : ""
                        }`,
                        DOB: `${data?.DOB ? data?.DOB : ""}`,
                        jobTitle: `${data?.jobTitle ? data?.jobTitle : ""}`,
                        clientRate: `${
                          data?.clientRate ? data?.clientRate : ""
                        }`,
                        contractorId: `${
                          data?.contractorId ? data?.contractorId : ""
                        }`,
                        joiningDate: `${
                          data?.joiningDate ? data?.joiningDate : ""
                        }`,
                        totalYearExperince: `${
                          data?.totalYearExperince
                            ? data?.totalYearExperince
                            : ""
                        }`,
                        guddgeEmailPlan: `${
                          data?.guddgeEmailPlan ? data?.guddgeEmailPlan : ""
                        }`,
                        agreementEndDate: `${
                          data?.agreementEndDate ? data?.agreementEndDate : ""
                        }`,
                        shore: `${data?.shore ? data?.shore : ""}`,
                        contractorRate: `${
                          data?.contractorRate ? data?.contractorRate : ""
                        }`,
                        companyName: `${
                          data?.companyName ? data?.companyName : ""
                        }`,
                        identificationNumber: `${
                          data?.identificationNumber
                            ? data?.identificationNumber
                            : ""
                        }`,
                        socialSecurityNumber: `${
                          data?.socialSecurityNumber
                            ? data?.socialSecurityNumber
                            : ""
                        }`,
                        mailingAddress: `${
                          data?.mailingAddress ? data?.mailingAddress : ""
                        }`,
                        emailingAddressForSoftCopies: `${
                          data?.alternativeEmailAdress
                            ? data?.alternativeEmailAdress
                            : ""
                        }`,
                      }}
                      onSubmit={async (values) => {
                        values.identificationNumber =
                          values.identificationNumber
                            ? parseInt(values.identificationNumber, 10)
                            : "";
                        values.socialSecurityNumber =
                          values.socialSecurityNumber
                            ? parseInt(values.socialSecurityNumber, 10)
                            : "";
                        if (data?.role === "Contractor") {
                          if (file) {
                            try {
                              const res = await editContractor(
                                id,
                                handleFormData(values)
                              );
                              if (res?.data?.success) {
                                Swal.fire({
                                  width: "20em",
                                  height: "20em",
                                  position: "center",
                                  icon: "success",
                                  text: `${res?.data?.message}`,
                                  showConfirmButton: false,
                                  timer: 1500,
                                });
                                setEditModal(false);
                              } else {
                                Swal.fire({
                                  width: "20em",
                                  height: "20em",
                                  position: "center",
                                  icon: "error",
                                  text: `${res?.response?.data?.message}`,
                                });
                              }
                            } catch (error) {
                              console.log(error?.message);
                            }
                          } else {
                            try {
                              const res = await editContractor(id, values);
                              if (res?.data?.success) {
                                Swal.fire({
                                  width: "20em",
                                  height: "20em",
                                  position: "center",
                                  icon: "success",
                                  text: `${res?.data?.message}`,
                                  showConfirmButton: false,
                                  timer: 1500,
                                });
                                setEditModal(false);
                              } else {
                                Swal.fire({
                                  width: "20em",
                                  height: "20em",
                                  position: "center",
                                  icon: "error",
                                  text: `${res?.response?.data?.message}`,
                                });
                              }
                            } catch (error) {
                              console.log(error?.message);
                            }
                          }
                        } else {
                          try {
                            const res = await EditUserById(id, values);
                            if (res?.data?.success) {
                              Swal.fire({
                                width: "20em",
                                height: "20em",
                                position: "center",
                                icon: "success",
                                text: `${res?.data?.message}`,
                                showConfirmButton: false,
                                timer: 1500,
                              });
                              setEditModal(false);
                            } else {
                              Swal.fire({
                                width: "20em",
                                height: "20em",
                                position: "center",
                                icon: "error",
                                text: `${res?.response?.data?.message}`,
                              });
                            }
                          } catch (error) {
                            console.log(error?.message);
                          }
                        }
                      }}
                      enableReinitialize
                    >
                      {({
                        isSubmitting,
                        errors,
                        touched,
                        setFieldValue,
                        values,
                      }) => (
                        <Form>
                          <div className="flex flex-col py-3">
                            <label htmlFor="name" className="text-[#7A7A79]">
                              Name of {data?.role}
                            </label>
                            <Field
                              name="name"
                              placeholder="Enter Admin Name"
                              className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                touched.name && errors.name ? "is-invalid" : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="name"
                              className="text-red-700 font-normal font-base text-left"
                            />
                          </div>

                          <div className="flex flex-col mt-3">
                            <label htmlFor="email" className="text-[#7A7A79]">
                              Email
                            </label>
                            <Field
                              name="email"
                              placeholder="someone@example.com"
                              className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                touched.email && errors.email
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="email"
                              className="text-red-700 font-normal font-base text-left"
                            />
                          </div>

                          <div className="flex flex-col mt-3 py-3">
                            <label htmlFor="DOB" className="text-[#7A7A79]">
                              Date of Birth
                            </label>
                            <Field
                              name="DOB"
                              type="date"
                              className={`border text-[#11141C] border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                touched.DOB && errors.DOB ? "is-invalid" : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="DOB"
                              className="text-red-700 font-normal font-base text-left"
                            />
                          </div>

                          <div className="flex flex-col mt-3 py-3">
                            <label
                              htmlFor="phoneNumber"
                              className="text-[#7A7A79]"
                            >
                              Phone Number
                            </label>
                            <PhoneInput
                              country={"us"}
                              value={values?.phoneNumber}
                              onChange={(phone) =>
                                setFieldValue("phoneNumber", phone)
                              }
                              inputStyle={{ width: "100%" }}
                            />
                            {/* <ErrorMessage
                              component="div"
                              name="phoneNumber"
                              className="text-red-700 font-normal font-base text-left"
                            /> */}
                          </div>

                          {data?.role === "Contractor" ? (
                            <>
                              <div className="flex flex-col py-3">
                                <label
                                  htmlFor="jobTitle"
                                  className="text-[#7A7A79]"
                                >
                                  Job Title
                                </label>
                                <Field
                                  name="jobTitle"
                                  placeholder="Enter Job Title"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.jobTitle && errors.jobTitle
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="jobTitle"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex flex-col mt-3 py-3">
                                <label
                                  htmlFor="joiningDate"
                                  className="text-[#7A7A79]"
                                >
                                  Joining Date
                                </label>
                                <Field
                                  name="joiningDate"
                                  type="date"
                                  className={`border text-[#11141C] border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.joiningDate && errors.joiningDate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="joiningDate"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex flex-col mt-3 py-3">
                                <label
                                  htmlFor="totalYearExperince"
                                  className="text-[#7A7A79]"
                                >
                                  Total Years of Experince
                                </label>
                                <Field
                                  name="totalYearExperince"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.totalYearExperince &&
                                    errors.totalYearExperince
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="totalYearExperince"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex flex-col py-3">
                                <label
                                  htmlFor="guddgeEmailPlan"
                                  className="text-[#7A7A79]"
                                >
                                  Guddge Email Plan
                                </label>
                                <Field
                                  name="guddgeEmailPlan"
                                  placeholder="Enter Guddge Email Plan"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.guddgeEmailPlan &&
                                    errors.guddgeEmailPlan
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="guddgeEmailPlan"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex w-full flex-col mt-3 py-3">
                                <label
                                  htmlFor="agreement"
                                  className="text-[#7A7A79]"
                                >
                                  Agreement(PDF file only)
                                </label>
                                <Field name="agreement">
                                  {({ field }) => (
                                    <input
                                      type="file"
                                      value={data?.agreement}
                                      accept=".pdf"
                                      {...field}
                                      className="h-10 border border-1 border-[#B8B7B6] rounded mt-1"
                                      onChange={(e) => handlefile(e)}
                                    />
                                  )}
                                </Field>
                              </div>

                              <div className="flex flex-col mt-3 py-3">
                                <label
                                  htmlFor="agreementEndDate"
                                  className="text-[#7A7A79]"
                                >
                                  Agreement End Date
                                </label>
                                <Field
                                  name="agreementEndDate"
                                  type="date"
                                  className={`border text-[#11141C] border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.agreementEndDate &&
                                    errors.agreementEndDate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="agreementEndDate"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex flex-col mt-3 py-3">
                                <label
                                  htmlFor="contractorRate"
                                  className="text-[#7A7A79]"
                                >
                                  Contractor Rate
                                </label>
                                <Field
                                  name="contractorRate"
                                  type="text"
                                  className={`border text-[#11141C] border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.contractorRate &&
                                    errors.contractorRate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="contractorRate"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex flex-col mt-3 py-3">
                                <label
                                  htmlFor="shore"
                                  className="text-[#7A7A79]"
                                >
                                  ONSHORE/OFFSHORE
                                </label>
                                <Field
                                  as="select"
                                  name="shore"
                                  className={`border text-[#11141C] border-1 border-[#B8B7B6] rounded mt-1  pl-2 outline-none  ${
                                    touched.shore && errors.shore
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  <option>Select</option>
                                  <option value="onShore">onShore</option>
                                  <option value="offShore">offShore</option>
                                </Field>
                              </div>

                              <div className="flex flex-col py-3">
                                <label
                                  htmlFor="companyName"
                                  className="text-[#7A7A79]"
                                >
                                  Name of the Company
                                </label>
                                <Field
                                  name="companyName"
                                  placeholder="Enter company Name"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.companyName && errors.companyName
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="companyName"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex flex-col mt-3 py-3">
                                <label
                                  htmlFor="identificationNumber"
                                  className="text-[#7A7A79]"
                                >
                                  Entity Identification Number(EIN)
                                </label>
                                <Field
                                  name="identificationNumber"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.identificationNumber &&
                                    errors.identificationNumber
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="identificationNumber"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex flex-col mt-3 py-3">
                                <label
                                  htmlFor="socialSecurityNumber"
                                  className="text-[#7A7A79]"
                                >
                                  Social Security Number
                                </label>
                                <Field
                                  name="socialSecurityNumber"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.socialSecurityNumber &&
                                    errors.socialSecurityNumber
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="socialSecurityNumber"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex flex-col py-3">
                                <label
                                  htmlFor="mailingAddress"
                                  className="text-[#7A7A79]"
                                >
                                  Mailing Address
                                </label>
                                <Field
                                  name="mailingAddress"
                                  placeholder="Enter Mailing Address"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.mailingAddress &&
                                    errors.mailingAddress
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="mailingAddress"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex flex-col py-3">
                                <label
                                  htmlFor="alternativeEmailAdress"
                                  className="text-[#7A7A79]"
                                >
                                  Alternative Email Address
                                </label>
                                <Field
                                  name="alternativeEmailAdress"
                                  placeholder="Enter Alternative Email Address For Soft Copies"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.alternativeEmailAdress &&
                                    errors.alternativeEmailAdress
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="alternativeEmailAdress"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>
                            </>
                          ) : null}

                          {data?.role === "Client" ? (
                            <>
                              <div className="flex flex-col py-3">
                                <label
                                  htmlFor="companyName"
                                  className="text-[#7A7A79]"
                                >
                                  Name of the Company
                                </label>
                                <Field
                                  name="companyName"
                                  placeholder="Enter company Name"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.companyName && errors.companyName
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="companyName"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>
                              <div className="flex flex-col py-3">
                                <label
                                  htmlFor="clientRate"
                                  className="text-[#7A7A79]"
                                >
                                  Client Rate
                                </label>
                                <Field
                                  name="clientRate"
                                  placeholder="Enter client rate"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.clientRate && errors.clientRate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="clientRate"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex flex-col mt-3 py-3">
                                <label
                                  htmlFor="contractorId"
                                  className="text-[#7A7A79]"
                                >
                                  Select Contractor for Client
                                </label>
                                <Field
                                  as="select"
                                  name="contractorId"
                                  className={`border text-[#11141C] border-1 border-[#B8B7B6] rounded mt-1 outline-none  pl-2  ${
                                    touched.contractorId && errors.contractorId
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  <option className="text-base">Select</option>
                                  {cont?.map((obj, i) => (
                                    <option key={obj._id} value={`${obj._id}`}>
                                      {obj.name}
                                    </option>
                                  ))}
                                </Field>
                              </div>

                              <div className="flex flex-col py-3">
                                <label
                                  htmlFor="guddgeEmailPlan"
                                  className="text-[#7A7A79]"
                                >
                                  Guddge Email Plan
                                </label>
                                <Field
                                  name="guddgeEmailPlan"
                                  placeholder="Enter Guddge Email Plan"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.guddgeEmailPlan &&
                                    errors.guddgeEmailPlan
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="guddgeEmailPlan"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex flex-col mt-3 py-3">
                                <label
                                  htmlFor="identificationNumber"
                                  className="text-[#7A7A79]"
                                >
                                  Entity Identification Number(EIN)
                                </label>
                                <Field
                                  // type="number"
                                  name="identificationNumber"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.identificationNumber &&
                                    errors.identificationNumber
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="identificationNumber"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>

                              <div className="flex flex-col mt-3 py-3">
                                <label
                                  htmlFor="socialSecurityNumber"
                                  className="text-[#7A7A79]"
                                >
                                  Social Security Number
                                </label>
                                <Field
                                  // type="number"
                                  name="socialSecurityNumber"
                                  className={`border border-1 border-[#B8B7B6] rounded mt-1 h-[35px] pl-2 outline-none  ${
                                    touched.socialSecurityNumber &&
                                    errors.socialSecurityNumber
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="socialSecurityNumber"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>
                            </>
                          ) : null}

                          {data?.role === "Admin" ? null : (
                            <button
                              type="button"
                              className="font-normal bg-green-500 text-white text-sm w-full h-[44px] mt-3 rounded"
                              onClick={() => {
                                setShowModal(true);
                                setEditModal(false);
                              }}
                            >
                              Reset Password
                            </button>
                          )}

                          <div className="flex justify-between gap-4 py-3">
                            <button
                              type="button"
                              className="font-normal md:text-sm text-xs w-full h-[44px] mt-3 border border-gray-500 rounded"
                              onClick={() => {
                                setEditModal(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="flex items-center justify-center font-normal text-sm h-[44px] w-full bg-black  mt-3 text-white rounded"
                            >
                              <img src={create} alt="create" />
                              <p className="ml-2 md:text-sm text-xs">
                                Create {data?.role}
                              </p>
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <EditPassword setShowModal={setShowModal} showModal={showModal} id={id} />
    </>
  );
}
