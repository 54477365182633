import React, { useState, useEffect } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import { generateInvioce, getInvoiceById } from "../../Api";
import Swal from "sweetalert2";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../timeSheet/Spinner";
import { useUserContext } from "../../context";

export default function ViewInvoice() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const { user } = useUserContext();

  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    const res = await getInvoiceById(id);
    if (res?.status === 200) {
      setLoading(false);
      setData(res?.data?.data);
    }
  };

  return (
    <div className="flex flex-col w-full px-5">
      <div className="w-full h-screen px-2 overflow-y-auto">
        <Formik
          initialValues={{
            ...data,
          }}
          onSubmit={async (values) => {
            try {
              const res = await generateInvioce(values);
              if (res?.data?.success) {
                navigate(`/home`);
                Swal.fire({
                  width: "20em",
                  height: "20em",
                  position: "center",
                  icon: "success",
                  title: `${res?.data?.message}`,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } catch (error) {
              Swal.fire({
                width: "20em",
                height: "20em",
                title: `${error?.data?.message}`,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
            }
          }}
          enableReinitialize
        >
          {({ isSubmitting, values, setValues }) => (
            <Form className="w-full h-screen">
              <div>
                <div className="w-full ">
                  <div className="flex w-full flex-col overflow-x-auto">
                    <div className="sm:-mx-6 lg:-mx-8">
                      <div className=" py-2 sm:px-6 lg:px-8 ">
                        <FieldArray name="dataSheet">
                          {({ insert, remove, push }) => (
                            <div className=" rounded-lg">
                              <table className="table-auto text-left text-sm font-light w-full">
                                <thead className="border-b bg-[#E3E8EF] font-medium rounded-lg dark:border-neutral-500">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                                    >
                                      Month
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                    >
                                      ID
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                    >
                                      Invoice Category
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                    >
                                      Project
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                    >
                                      Task
                                    </th>
                                    {user?.role === "Admin" ||
                                    user?.role === "Super Admin" ? (
                                      <th
                                        scope="col"
                                        className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                      >
                                        Rate
                                      </th>
                                    ) : null}
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                    >
                                      Hour
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                                    >
                                      Comments (include risks, issues, details
                                      as applicable)
                                    </th>
                                  </tr>
                                </thead>
                                {values?.dataSheet?.length > 0 &&
                                  values?.dataSheet?.map((dataSheet, index) => (
                                    <tbody key={index} className="w-full">
                                      {loading ? (
                                        <tr className="h-10 w-full">
                                          <Spinner />
                                        </tr>
                                      ) : (
                                        <>
                                          <tr className="border-b dark:border-neutral-500">
                                            <td
                                              width="20%"
                                              className="whitespace-nowrap text-center "
                                            >
                                              <Field
                                                name={`dataSheet.${index}.changeDate`}
                                                disabled={true}
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="12-06-2021"
                                              />
                                            </td>
                                            <td className="whitespace-nowrap text-center ">
                                              <Field
                                                disabled={true}
                                                name={`dataSheet.${index}.ID`}
                                                value={
                                                  values?.dataSheet[index]?.ID
                                                }
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="126106"
                                              />
                                            </td>
                                            <td className="whitespace-nowrap text-center ">
                                              <Field
                                                name={`dataSheet.${index}.invoiceCategory`}
                                                disabled={true}
                                                value={
                                                  values?.dataSheet[index]
                                                    ?.invoiceCategory
                                                }
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="DFAS"
                                              />
                                            </td>
                                            <td className="whitespace-nowrap text-center ">
                                              <Field
                                                disabled={true}
                                                name={`dataSheet.${index}.project`}
                                                value={
                                                  values?.dataSheet[index]
                                                    ?.project
                                                }
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="Data Foundation"
                                              />
                                            </td>
                                            <td className="whitespace-nowrap text-center ">
                                              <Field
                                                disabled={true}
                                                name={`dataSheet.${index}.task`}
                                                value={
                                                  values?.dataSheet[index]?.task
                                                }
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="Added a new View"
                                              />
                                            </td>
                                            {user?.role === "Admin" ||
                                            user?.role === "Super Admin" ? (
                                              <td className="whitespace-nowrap text-center ">
                                                <Field
                                                  name={`dataSheet.${index}.rate`}
                                                  value={
                                                    values?.dataSheet[index]
                                                      ?.rate
                                                  }
                                                  className="h-full border-none outline-none w-full px-6 py-4"
                                                  // placeholder="0"
                                                />
                                              </td>
                                            ) : null}
                                            <td className="whitespace-nowrap text-center ">
                                              <Field
                                                disabled={true}
                                                name={`dataSheet.${index}.hour`}
                                                value={
                                                  values?.dataSheet[index]?.hour
                                                }
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="0"
                                              />
                                            </td>
                                            <td className="whitespace-nowrap text-center">
                                              <Field
                                                disabled={true}
                                                name={`dataSheet.${index}.comments`}
                                                value={
                                                  values?.dataSheet[index]
                                                    ?.comments
                                                }
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="Include October hours"
                                              />
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                    </tbody>
                                  ))}
                              </table>
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-5">
                  <FieldArray name="miscellaneous">
                    {({ insert, remove, push }) => (
                      <div className="">
                        <h1 className="text-lg font-bold">Add Miscellaneous</h1>
                        {values?.miscellaneous?.length > 0 &&
                          values?.miscellaneous?.map((obj, i) => (
                            <div
                              key={i}
                              className="flex md:flex-row flex-col gap-x-4 gap-y-4"
                            >
                              <div className="w-full">
                                <label className="text-base font-semibold">
                                  Cost
                                </label>
                                <Field
                                  disabled={true}
                                  name={`miscellaneous.${i}.cost`}
                                  className={`border-gray-500 border rounded-lg mt-1 outline-none w-full pl-2 py-2`}
                                />
                              </div>
                              <div className="w-full">
                                <label className="text-base font-semibold">
                                  Reason/Note
                                </label>
                                <Field
                                  disabled={true}
                                  name={`miscellaneous.${i}.reason`}
                                  className={`border-gray-500 border rounded-lg mt-1 outline-none w-full pl-2 py-2`}
                                />
                              </div>
                              <div className="w-full">
                                <label className="text-base font-semibold">
                                  Date
                                </label>
                                <Field
                                  disabled={true}
                                  name={`miscellaneous.${i}.date`}
                                  type="date"
                                  className={`border rounded-lg outline-none mt-1 w-full pl-2`}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </FieldArray>
                  <div className="w-32 py-3 ">
                    <label className="text-base font-medium">Total</label>
                    <Field
                      disabled={true}
                      name={`values.total`}
                      value={values?.total}
                      className="h-full border-none outline-none w-full px-6 py-4"
                    />
                  </div>
                </div>

                {/* <div className="pb-7 flex flex-col gap-x-3 mt-14 lg:flex lg:flex-row md:flex-row  md:flex lg:justify-end md:justify-end justify-center items-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="font-normal h-11 w-32 bg-black text-white rounded flex items-center gap-x-1 justify-center"
                  >
                    <CreateIcon />
                    <p>Save</p>
                  </button>
                </div> */}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
