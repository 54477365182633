import React from "react";
import { Link } from "react-router-dom";
import angle from "../../assests/angleIcon.svg";
import ViewProjectTable from "./ViewProjectTable";
import { useUserContext } from "../../context";

export default function TimeViewSheets() {
  const { user } = useUserContext();
  return (
    <>
      <div className="lg:w-full md:w-[750px] w-full">
        <div className=" flex lg:items-start md:items-start lg:justify-start lg:flex-row md:justify-start md:flex-row flex-col justify-center items-center my-2">
          <Link to={`/timesheets/draft/${user?.userId}`}>
            <div className="w-[44px] self-start h-[44px] hover:bg-[#F0FAFC] flex items-center justify-center rounded lg:mt-0">
              <img src={angle} alt="back" />
            </div>
          </Link>
        </div>

        <div className="px-4 mt-3">
          <ViewProjectTable />
        </div>
      </div>
    </>
  );
}
