import React, { useState, useEffect } from "react";
import { CreateIcon, AddIcon } from "../iconComponent/Icon";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import { getDaysInMonth, getYear, getMonth, format } from "date-fns";
import { timeSheetData, allCalendarEvent } from "../../Api";
import Swal from "sweetalert2";
import { useUserContext } from "../../context";
import * as xlsx from "xlsx";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const tableSchema = Yup.object().shape({
  miscellaneous: Yup.array().of(
    Yup.object().shape({
      cost: Yup.string().matches(/^\d+$/, "cost must be digits").nullable(),
    })
  ),
});

export default function ViewProjectTable() {
  const [table, setTable] = useState(false);
  const [isCliked, setIsClicked] = useState(false);
  const [highLightRow, setHighLightRow] = useState([]);
  const [indices, setIndices] = useState([]);
  const [events, setEvents] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [addName, setAddName] = useState();
  const [addDate, setAddDate] = useState();
  const [height, setHeight] = useState(false);

  const navigate = useNavigate();
  const { user } = useUserContext();

  useEffect(() => {
    getEvents();
  }, []);
  const getEvents = async () => {
    const res = await allCalendarEvent();
    setEvents(res?.data?.allevents);
  };

  const hanldePush = (push, currentDate) => {
    if (!currentDate) {
      Swal.fire({
        width: "16em",
        height: "16em",
        position: "center",
        icon: "error",
        title: "Please Select Timesheet month",
      });
    } else {
      formatDate(currentDate);
      setHeight(true);
      let days = getDaysInMonth(new Date(currentDate));
      setIsClicked(true);
      setTable(true);
      let year = getYear(new Date(currentDate));
      let month = getMonth(new Date(currentDate));
      if (month + 1 < 10) {
        setAddDate(`${year}-0${month + 1}`);
      } else {
        setAddDate(`${year}-${month + 1}`);
      }
      for (let i = 0; i < days; i++) {
        let val = format(new Date(year, month, i + 1), "MM/dd/yyyy");
        let weekDay = format(new Date(year, month, i + 1), "eeee");

        if (weekDay === "Saturday" || weekDay === "Sunday") {
          setIndices((prevIndices) => [...prevIndices, i]);
        }

        const filterDate = expandedEvents?.filter((obj) => {
          if (format(new Date(obj.start), "MM/dd/yyyy") === val) {
            setHighLightRow((preHightLight) => [...preHightLight, i]);
          }

          return format(new Date(obj.start), "MM/dd/yyyy") === val;
        });

        if (weekDay !== "Saturday" && weekDay !== "Sunday") {
          push({
            changeDate: ` ${val}`,
            ID: `${filterDate[0] ? filterDate[0].title : ""}`,
            hour: `${filterDate[0] ? filterDate[0].title : ""}`,
            invoiceCategory: `${filterDate[0] ? filterDate[0].title : ""}`,
            project: `${filterDate[0] ? filterDate[0].title : ""}`,
            task: `${filterDate[0] ? filterDate[0].title : ""}`,
            comments: `${filterDate[0] ? filterDate[0].title : ""}`,
          });
        } else {
          push({
            changeDate: `${val}`,
            ID: `${filterDate[0] ? filterDate[0].title : "N/A "}`,
            hour: `${filterDate[0] ? filterDate[0].title : "N/A"}`,
            invoiceCategory: `${filterDate[0] ? filterDate[0].title : "N/A"}`,
            project: `${filterDate[0] ? filterDate[0].title : "N/A"}`,
            task: `${filterDate[0] ? filterDate[0].title : "N/A"}`,
            comments: `${filterDate[0] ? filterDate[0].title : "N/A"}`,
          });
        }
      }
    }
  };

  const readExcel = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer(file);
    const excelfile = xlsx.read(data);
    const excelsheet = excelfile.Sheets[excelfile.SheetNames[0]];
    const exceljson = xlsx.utils.sheet_to_json(excelsheet);
    const modifiedData = exceljson?.map((row) => {
      const modifiedRow = {};

      // Map Excel field names to your desired field names
      modifiedRow["changeDate"] = row["Changes Date"];
      modifiedRow["ID"] = row["ID"];
      modifiedRow["invoiceCategory"] = row["INVOICE CATEGORIES"];
      modifiedRow["project"] = row["PROJECT"];
      modifiedRow["task"] = row["TASK"];
      modifiedRow["hour"] = row["HOUR"];
      modifiedRow["comments"] =
        row["COMMENTS (include risks, issues, details as applicable)"];

      return modifiedRow;
    });
    setExcelData(modifiedData);
    setHeight(true);
  };

  function handleExcelDate(value) {
    const regex = /^\d+(\.\d+)?$/;
    const isValid = regex.test(value);
    if (isValid) {
      const date = format(
        new Date(Math.round((value - 25569) * 86400 * 1000)),
        "MM/dd/yyyy"
      );
      return date;
    } else {
      return value;
    }
  }

  const handleDraft = async (values) => {
    try {
      const res = await timeSheetData({
        ...values,
        status: "draft",
      });
      console.log(res);
      if (res?.data?.success) {
        navigate(`/timesheets/draft/${user?.userId}`);
        Swal.fire({
          width: "20em",
          height: "20em",
          position: "center",
          icon: "success",
          title: `${res?.data?.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        width: "20em",
        height: "20em",
        title: `${error?.data?.message}`,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    }
  };

  function formatDate(input) {
    // Create a new Date object based on the input
    const date = new Date(input); // Adding "-01" to ensure it's recognized as the first day of the month

    // Create an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract the month name from the array
    const month = monthNames[date.getMonth()];

    // Extract the year from the date object
    const year = date.getFullYear();
    return setAddName(`${month} ${year} ${user?.name}`);
  }

  const generateDateList = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dates = [];

    while (startDate <= endDate) {
      dates.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }

    return dates;
  };

  const expandedEvents = [];

  events?.forEach((event) => {
    const dates = generateDateList(event.start, event.end);
    dates.forEach((date) => {
      const formattedDate = date.toISOString().split("T")[0]; // Convert the Date object back to "YYYY-MM-DD" format
      const newEvent = {
        ...event,
        start: formattedDate,
        end: formattedDate,
      };
      expandedEvents.push(newEvent);
    });
  });
  const currentYear = new Date().getFullYear();
  const minDate = `${currentYear}-01-01`; // 1st of January of the current year
  const maxDate = `${currentYear}-12-31`;

  return (
    <>
      <Formik
        validationSchema={tableSchema}
        initialValues={{
          user: `${user?.userId}`,
          name: `${user?.name}`,
          status: "",
          timeSheetName: "",
          currentDate: "",
          dataSheet: [...excelData],
          miscellaneous: [
            {
              cost: "",
              reason: "",
              date: "",
            },
          ],
        }}
        onSubmit={async (values) => {
          try {
            const res = await timeSheetData({
              ...values,
              status: "Need approval",
              timeSheetName: addName,
            });
            if (res?.data?.success) {
              navigate(`/home`);
              Swal.fire({
                width: "20em",
                height: "20em",
                position: "center",
                icon: "success",
                title: `${res?.data?.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } catch (error) {
            Swal.fire({
              width: "20em",
              height: "20em",
              title: `${error?.data?.message}`,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            });
          }
        }}
        enableReinitialize
      >
        {({
          isSubmitting,
          errors,
          touched,
          values,
          setValues,
          resetForm,
          setFieldValue,
        }) => (
          <Form>
            <div className="flex items-center lg:flex-row flex-col lg:justify-end gap-3 pb-5">
              <label className="lg:self-start mt-2 font-medium text-lg ">
                Import from xls
              </label>
              <input
                type="file"
                className="border rounded-lg"
                onChange={(e) => readExcel(e)}
              />
            </div>
            <div className="flex gap-x-3 lg:flex-row flex-col md:flex-col justify-center items-center">
              <div className="flex w-2/4 flex-col py-2">
                <label
                  htmlFor="timeSheetName"
                  className="text-sm text-[#7A7A79]"
                >
                  Timesheet Name
                </label>
                <Field
                  name="timeSheetName"
                  value={addName ? addName : ""}
                  onChange={(e) => setAddName(e.target.value)}
                  className={`border border-1 border-[#B8B7B6] mt-1 h-[35px] pl-2 outline-none ${
                    touched.timeSheetName && errors.timeSheetName
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </div>
              <div className="flex w-2/4 flex-col py-2">
                <label htmlFor="currentDate" className="text-sm text-[#7A7A79]">
                  Timesheet Month
                </label>
                <Field
                  name="currentDate"
                  type="date"
                  min={minDate}
                  max={maxDate}
                  className={`border-b border-1 border-[#B8B7B6] mt-1 h-[35px] pl-2 outline-none  ${
                    touched.currentDate && errors.currentDate
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </div>
            </div>

            <div className="w-full">
              <div className="w-full">
                <hr className="w-full pb-5" />
                <div className="flex justify-between">
                  <p className="font-medium text-sm text-[#075A66] py-2">
                    Add Table
                  </p>
                  <button
                    type="button"
                    className="border bg-green-500 text-white px-3 py-2 my-1 rounded-lg"
                    onClick={() => {
                      let filledRowIndex = values?.dataSheet?.findIndex(
                        (item) => item.ID || item.invoiceCategory
                      );
                      if (filledRowIndex === -1) {
                        alert("No row has been filled yet.");
                        return;
                      }
                      const identity = values?.dataSheet[filledRowIndex].ID;
                      const invoice =
                        values?.dataSheet[filledRowIndex].invoiceCategory;
                      const project = values?.dataSheet[filledRowIndex].project;
                      const task = values?.dataSheet[filledRowIndex].task;
                      const hour = values?.dataSheet[filledRowIndex].hour;
                      const comments =
                        values?.dataSheet[filledRowIndex].comments;

                      values?.dataSheet?.forEach((_, i) => {
                        if (!indices.includes(i)) {
                          if (!highLightRow.includes(i)) {
                            setFieldValue(`dataSheet[${i}].ID`, identity);
                            setFieldValue(
                              `dataSheet[${i}].invoiceCategory`,
                              invoice
                            );
                            setFieldValue(`dataSheet[${i}].project`, project);
                            setFieldValue(`dataSheet[${i}].task`, task);
                            setFieldValue(`dataSheet[${i}].hour`, hour);
                            setFieldValue(`dataSheet[${i}].comments`, comments);
                          }
                        }
                      });
                    }}
                  >
                    AutoFill
                  </button>
                </div>

                <div className="flex flex-col w-full">
                  <div className="w-full">
                    <div className=" w-full ">
                      <FieldArray name="dataSheet">
                        {({ insert, remove, push }) => (
                          <div
                            className={`rounded-lg w-full overflow-x-auto ${
                              height ? "h-screen" : ""
                            } 
                            fixTableHead
                            `}
                          >
                            <table className="table-auto min-w-full divide-y text-left text-sm font-light w-full">
                              <thead className="border-b bg-[#E3E8EF] font-medium rounded-lg dark:border-neutral-500">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083] tracking-wider"
                                  >
                                    Changes Date
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083] tracking-wider"
                                  >
                                    ID
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083] tracking-wider"
                                  >
                                    Invoice Category
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083] tracking-wider"
                                  >
                                    Project &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083] tracking-wider"
                                  >
                                    Task
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083] tracking-wider"
                                  >
                                    Hour
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083] tracking-wider"
                                  >
                                    Comments (include risks, issues, details as
                                    applicable)
                                  </th>
                                </tr>
                              </thead>
                              {values?.dataSheet?.length > 0 &&
                                values?.dataSheet?.map((dataSheet, index) => (
                                  <tbody
                                    key={index}
                                    className={`overflow-y-auto divide-y h-[calc(100%-2.5rem)]`}
                                  >
                                    {excelData.length > 0 ? (
                                      <>
                                        <tr className="border-b dark:border-neutral-500">
                                          <td
                                            width="20%"
                                            className="whitespace-nowrap text-center "
                                          >
                                            <Field
                                              name={`dataSheet.${index}.changeDate`}
                                              disabled={true}
                                              value={handleExcelDate(
                                                values?.dataSheet[index]
                                                  ?.changeDate,
                                                index
                                              )}
                                              className="h-full border-none outline-none w-full px-6 py-4"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap text-center ">
                                            <Field
                                              name={`dataSheet.${index}.ID`}
                                              value={
                                                values?.dataSheet[index]?.ID
                                              }
                                              className="h-full border-none outline-none w-full px-6 py-4"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap text-center ">
                                            <Field
                                              name={`dataSheet.${index}.invoiceCategory`}
                                              value={
                                                values?.dataSheet[index]
                                                  ?.invoiceCategory
                                              }
                                              className="h-full border-none outline-none w-full px-6 py-4"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap text-center ">
                                            <Field
                                              name={`dataSheet.${index}.project`}
                                              value={
                                                values?.dataSheet[index]
                                                  ?.project
                                              }
                                              className="h-full border-none outline-none w-full px-6 py-4"
                                              // placeholder="Data Foundation"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap text-center ">
                                            <Field
                                              name={`dataSheet.${index}.task`}
                                              value={
                                                values?.dataSheet[index]?.task
                                              }
                                              className="h-full border-none outline-none w-full px-6 py-4"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap text-center ">
                                            <Field
                                              name={`dataSheet.${index}.hour`}
                                              type="number"
                                              min="0"
                                              value={
                                                values?.dataSheet[index]?.hour
                                              }
                                              className="h-full border-none outline-none w-full px-6 py-4"
                                            />
                                            <ErrorMessage
                                              component="div"
                                              name={`dataSheet.${index}.hour`}
                                              className="text-red-700 font-normal font-base text-left"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap text-center">
                                            <Field
                                              name={`dataSheet.${index}.comments`}
                                              value={
                                                values?.dataSheet[index]
                                                  ?.comments
                                              }
                                              className="h-full border-none outline-none w-full px-6 py-4"
                                              // placeholder="Include October hours"
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    ) : null}

                                    {table ? (
                                      <>
                                        <tr className="border-b dark:border-neutral-500">
                                          <td
                                            width="20%"
                                            className="whitespace-nowrap text-center "
                                          >
                                            <Field
                                              name={`dataSheet.${index}.changeDate`}
                                              disabled={true}
                                              className={` ${
                                                highLightRow.filter(
                                                  (q) => q === index
                                                )[0] === index
                                                  ? "bg-red-400 text-white"
                                                  : indices.filter(
                                                      (p) => p === index
                                                    )[0] === index
                                                  ? "bg-[#81A46E] text-white"
                                                  : ""
                                              } h-full border-none outline-none w-full px-6 py-4`}
                                            />
                                          </td>
                                          <td className="whitespace-nowrap text-center ">
                                            <Field
                                              name={`dataSheet.${index}.ID`}
                                              className={` ${
                                                highLightRow.filter(
                                                  (q) => q === index
                                                )[0] === index
                                                  ? "bg-red-400 text-white"
                                                  : indices.filter(
                                                      (p) => p === index
                                                    )[0] === index
                                                  ? "bg-[#81A46E] text-white"
                                                  : ""
                                              } h-full border-none outline-none w-full px-6 py-4`}
                                            />
                                          </td>
                                          <td className="whitespace-nowrap text-center ">
                                            <Field
                                              name={`dataSheet.${index}.invoiceCategory`}
                                              className={` ${
                                                highLightRow.filter(
                                                  (q) => q === index
                                                )[0] === index
                                                  ? "bg-red-400 text-white"
                                                  : indices.filter(
                                                      (p) => p === index
                                                    )[0] === index
                                                  ? "bg-[#81A46E] text-white"
                                                  : ""
                                              } h-full border-none outline-none w-full px-6 py-4`}
                                              // placeholder="DFAS"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap text-center ">
                                            <Field
                                              name={`dataSheet.${index}.project`}
                                              className={` ${
                                                highLightRow.filter(
                                                  (q) => q === index
                                                )[0] === index
                                                  ? "bg-red-400 text-white"
                                                  : indices.filter(
                                                      (p) => p === index
                                                    )[0] === index
                                                  ? "bg-[#81A46E] text-white"
                                                  : ""
                                              } h-full border-none outline-none w-full px-6 py-4`}
                                              // placeholder="Data Foundation"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap text-center ">
                                            <Field
                                              name={`dataSheet.${index}.task`}
                                              className={` ${
                                                highLightRow.filter(
                                                  (q) => q === index
                                                )[0] === index
                                                  ? "bg-red-400 text-white"
                                                  : indices.filter(
                                                      (p) => p === index
                                                    )[0] === index
                                                  ? "bg-[#81A46E] text-white"
                                                  : ""
                                              } h-full border-none outline-none w-full px-6 py-4`}
                                              // placeholder="Added a new View"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap text-center ">
                                            <Field
                                              type="number"
                                              min="0"
                                              name={`dataSheet.${index}.hour`}
                                              className={` ${
                                                highLightRow.filter(
                                                  (q) => q === index
                                                )[0] === index
                                                  ? "bg-red-400 text-white"
                                                  : indices.filter(
                                                      (p) => p === index
                                                    )[0] === index
                                                  ? "bg-[#81A46E] text-white"
                                                  : ""
                                              } h-full border-none outline-none w-full px-6 py-4`}
                                              // placeholder="0"
                                            />
                                          </td>
                                          <td className="whitespace-nowrap text-center">
                                            <Field
                                              name={`dataSheet.${index}.comments`}
                                              className={` ${
                                                highLightRow.filter(
                                                  (q) => q === index
                                                )[0] === index
                                                  ? "bg-red-400 text-white"
                                                  : indices.filter(
                                                      (p) => p === index
                                                    )[0] === index
                                                  ? "bg-[#81A46E] text-white"
                                                  : ""
                                              } h-full border-none outline-none w-full px-6 py-4`}
                                              // placeholder="Include October hours"
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    ) : null}
                                  </tbody>
                                ))}
                            </table>

                            <div className="flex justify-center pb-6 items-center mt-7">
                              <button
                                type="button"
                                disabled={isCliked}
                                onClick={() => {
                                  hanldePush(push, values?.currentDate);
                                }}
                                className={`w-[166px] h-[36px]  flex justify-center items-center gap-x-2 border border-dotted border-black rounded-[4px]`}
                              >
                                <AddIcon />
                                <p>Add Table</p>
                              </button>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-5">
                <h1 className="text-base font-semibold">
                  Miscellaneous expenses
                </h1>

                <FieldArray name="miscellaneous">
                  {({ insert, remove, push }) => (
                    <div className="">
                      <div className="lg:flex md:flex-row md:flex hidden w-full flex-col lg:gap-x-44 md:gap-x-52 gap-y-5">
                        <label className="text-base font-semibold">$Cost</label>
                        <label className="text-base font-semibold">
                          Reason
                        </label>
                        <label className="text-base font-semibold lg:-ml-4 md:-ml-5">
                          Date
                        </label>
                      </div>
                      {values?.miscellaneous?.length > 0 &&
                        values?.miscellaneous?.map((obj, i) => (
                          <div
                            key={i}
                            className="flex md:flex-row flex-col gap-x-4 gap-y-5"
                          >
                            <div className="w-full">
                              <label className="text-base md:hidden block font-semibold">
                                $Cost
                              </label>

                              <Field
                                placeholder="Enter cost"
                                name={`miscellaneous.${i}.cost`}
                                className={`border-gray-500 border rounded-lg mt-1 outline-none w-full pl-2 py-2`}
                              />
                              <ErrorMessage
                                component="div"
                                name={`miscellaneous.${i}.cost`}
                                className="text-red-700 font-normal font-base text-left"
                              />
                            </div>
                            <div className="w-full">
                              <label className="text-base md:hidden block font-semibold">
                                Reason
                              </label>
                              <Field
                                placeholder="Reason"
                                name={`miscellaneous.${i}.reason`}
                                className={`border-gray-500 border rounded-lg mt-1 outline-none w-full pl-2 py-2`}
                              />
                            </div>
                            <div className="w-full">
                              <label className="text-base md:hidden block font-semibold lg:-ml-4 md:-ml-5">
                                Date
                              </label>
                              <Field
                                name={`miscellaneous.${i}.date`}
                                type="date"
                                disabled={addDate ? false : true}
                                min={`${addDate}-01`}
                                max={`${addDate}-30`}
                                className={`border placeholder:text-black rounded-lg outline-none mt-1 w-full pl-2`}
                              />
                            </div>
                          </div>
                        ))}
                      <div className=" flex justify-center pb-6 items-center mt-7">
                        <button
                          type="button"
                          onClick={() => {
                            push({
                              cost: "",
                              reason: "",
                              date: "",
                            });
                          }}
                          className={`w-[166px] h-[36px]  flex justify-center items-center gap-x-2 border border-dotted border-black rounded-[4px]`}
                        >
                          <AddIcon />
                          <p>Add Miscellaneous</p>
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>

              <div className="pb-7 flex flex-col gap-x-3 gap-y-5 mt-14 lg:flex lg:flex-row md:flex-row  md:flex lg:justify-end md:justify-end justify-center items-center">
                <button
                  type="button"
                  onClick={() => {
                    resetForm({
                      values: {
                        user: `${user?.userId}`,
                        name: `${user?.name}`,
                        status: "",
                        timeSheetName: "",
                        currentDate: "",
                        dataSheet: [...excelData],
                        miscellaneous: [
                          {
                            cost: "",
                            reason: "",
                            date: "",
                          },
                        ],
                      },
                    });
                    setHeight(false);
                    setIsClicked(false);
                  }}
                  className="font-normal h-11 w-40 border border-gray-500 rounded flex items-center gap-x-1 justify-center"
                >
                  <p>Cancel</p>
                </button>
                {user?.role === "Contractor" ? (
                  <button
                    type="button"
                    onClick={() => handleDraft(values)}
                    className="font-normal h-11 w-40 bg-black text-white rounded flex items-center gap-x-1 justify-center"
                  >
                    <CreateIcon />
                    <p>Save as draft</p>
                  </button>
                ) : null}
                <button
                  type="submit  "
                  onSubmit={() =>
                    setValues({
                      ...values,
                      status: "Need approval",
                    })
                  }
                  disabled={isSubmitting}
                  className="font-normal h-11 w-60 bg-green-500 text-white rounded flex items-center gap-x-1 justify-center"
                >
                  <CreateIcon />
                  <p>Share with Admin and save</p>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
