import instance from "axios";
import Swal from "sweetalert2";

const axios = instance.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setAuthToken = (token) => {
  if (token) {
    //applying token
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    //deleting the token from header
    delete axios.defaults.headers.common["Authorization"];
  }
};

axios.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.token) {
      config.headers.Authorization = `Bearer ${user?.token}`;
      return config;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const signupUser = async (form) => {
  try {
    const res = await axios.post(`/register-by-email`, form);
    return res;
  } catch (error) {
    Swal.fire({
      width: "20em",
      height: "20em",
      position: "center",
      icon: "error",
      title: "Sorry...",
      text: `${error?.response?.data?.message}`,
    });
    return error;
  }
};

export const loginUser = async (user) => {
  try {
    const res = await axios.post(`/login-by-email`, user);
    return res;
  } catch (error) {
    Swal.fire({
      width: "20em",
      height: "20em",
      position: "center",
      icon: "error",
      title: "Sorry...",
      text: `${error?.response?.data?.message}`,
    });
    return error;
  }
};

export const expireToken = async (token) => {
  try {
    const res = await axios.post(`/user/${token}`);
    return res;
  } catch (error) {
    Swal.fire({
      width: "20em",
      height: "20em",
      position: "center",
      icon: "error",
      title: "Sorry...",
      text: `${error?.response?.data?.message}`,
    });
    return error;
  }
};

export const forgetPassword = async (email) => {
  try {
    const res = await axios.post(`/forget-password`, email);
    return res;
  } catch (error) {
    Swal.fire({
      width: "20em",
      height: "20em",
      position: "center",
      icon: "error",
      title: "Sorry...",
      text: `${error?.response?.data?.message}`,
    });
    return error;
  }
};

export const updateForgetPassword = async (password, token) => {
  try {
    const res = await axios.post(`/forget-password/${token}`, password);
    return res;
  } catch (error) {
    Swal.fire({
      width: "20em",
      height: "20em",
      position: "center",
      icon: "error",
      title: "Sorry...",
      text: `${error?.message}`,
    });
    return error;
  }
};

export const createContractor = async (data) => {
  try {
    const res = await axios.post(`/pdfupload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (error) {
    Swal.fire({
      width: "20em",
      height: "20em",
      position: "center",
      icon: "error",
      title: "Sorry...",
      text: ` ${error?.response?.data?.message}`,
    });
    return error;
  }
};

export const editContractor = async (id, data) => {
  try {
    const res = await axios.put(`/pdfupload-edit/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (error) {
    Swal.fire({
      width: "20em",
      height: "20em",
      position: "center",
      icon: "error",
      title: "Sorry...",
      text: ` ${error?.response?.data?.message}`,
    });
    return error;
  }
};

export const userContractor = async (data) => {
  try {
    const res = await axios.put(`/contractors`, data);
    return res;
  } catch (error) {
    Swal.fire({
      width: "20em",
      height: "20em",
      position: "center",
      icon: "error",
      title: "Sorry...",
      text: ` ${error?.response?.data?.message}`,
    });
    return error;
  }
};

export const userClient = async (data) => {
  try {
    const res = await axios.put(`/clients`, data);
    return res;
  } catch (error) {
    Swal.fire({
      width: "20em",
      height: "20em",
      position: "center",
      icon: "error",
      title: "Sorry...",
      text: ` ${error?.response?.data?.message}`,
    });
    return error;
  }
};

export const timeSheetData = async (data) => {
  try {
    const res = await axios.post(`/view-time-sheets`, data);
    return res;
  } catch (error) {
    console.log(error);
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const userAdmin = async (data) => {
  try {
    const res = await axios.put(`/admin`, data);
    return res;
  } catch (error) {
    Swal.fire({
      width: "20em",
      height: "20em",
      position: "center",
      icon: "error",
      title: "Sorry...",
      text: ` ${error?.response?.data?.message}`,
    });
    return error;
  }
};

export const allAdmins = async (q) => {
  q = q || "";
  try {
    const res = await axios.get(`/view-time-sheets?q=${q}`);
    return res;
  } catch (error) {
    console.log(error);
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const shareWithGuddge = async (data) => {
  try {
    const res = await axios.put(`/view-time-sheets`, data);
    return res;
  } catch (error) {
    // Swal.fire({
    console.log(error);
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const getApproval = async (id) => {
  try {
    const res = await axios.get(`/timesheets/approval/${id}`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const allAdminApproval = async () => {
  try {
    const res = await axios.get(`/timesheets/approval`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const getDataSheetById = async (id) => {
  try {
    const res = await axios.get(`/timesheets/${id}`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const getApprovedById = async (id, data) => {
  try {
    const res = await axios.put(`/timesheets/${id}`, data);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const allApproved = async (id) => {
  try {
    const res = await axios.get(`/timesheets/approved/${id}`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const allAdminApproved = async () => {
  try {
    const res = await axios.get(`/timesheets/approved`);

    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const allDraft = async (id) => {
  try {
    const res = await axios.get(`/timesheets/draft/${id}`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const allAdminDraft = async () => {
  try {
    const res = await axios.get(`/timesheets/draft`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const allRejected = async (id) => {
  try {
    const res = await axios.get(`/timesheets/rejected/${id}`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const allAdminRejected = async (id) => {
  try {
    const res = await axios.get(`/timesheets/rejected`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const deleteById = async (id) => {
  try {
    const res = await axios.delete(`/timesheets/${id}`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const editDataById = async (id, data) => {
  try {
    const res = await axios.put(`/view-time-sheets/${id}`, data);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const getDataById = async (id) => {
  try {
    const res = await axios.get(`/view-time-sheets/${id}`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const addEvent = async (data) => {
  try {
    const res = await axios.post(`/calender`, data);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const allCalendarEvent = async () => {
  try {
    const res = await axios.get(`/calender`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const allHolidays = async () => {
  try {
    const res = await axios.get(`/calendar`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const allUserBirthDays = async (query) => {
  query = query || "";
  try {
    const res = await axios.get(`/all-users-date-of-birth?q=${query}`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const rejectDescById = async (id, data) => {
  try {
    const res = await axios.put(`/timesheets/desc/${id}`, data);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const msOfficeLogin = async (data) => {
  try {
    const res = await axios.post(`/timesheet`, data);
    return res;
  } catch (error) {
    return error;
  }
};

export const allTimeSheetReports = async (q) => {
  q = q || "";
  try {
    const res = await axios.get(
      `/timesheets-reports?contractor=${q?.contactor}&startDate=${q?.startDate}&endDate=${q?.endDate}&category=${q?.category}&ID=${q?.ID}&hour=${q?.hour}&rate=${q?.rate}&project=${q?.project}&task=${q?.task}&timesheetCreateAtStart=${q?.timesheetCreateAtStart}&timesheetCreateAtEnd=${q?.timesheetCreateAtEnd}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const allContractors = async () => {
  try {
    const res = await axios.get(`/all-contractors`);
    return res;
  } catch (error) {
    return error;
  }
};

export const allApprovalDates = async () => {
  try {
    const res = await axios.get(`/all-approved-dates`);
    return res;
  } catch (error) {
    return error;
  }
};

export const clientRates = async () => {
  try {
    const res = await axios.get(`/client-rates`);
    return res;
  } catch (error) {
    return error;
  }
};

export const generateInvioce = async (data) => {
  try {
    const res = await axios.post(`/generate-invoice`, data);
    return res;
  } catch (error) {
    return error;
  }
};

export const allInvoiceReports = async (q) => {
  q = q || "";
  try {
    const res = await axios.get(
      `/invoice-reports?status=${q.status}&startDate=${q.startDate}&endDate=${q.endDate}&client=${q.client}`
    );
    return res;
  } catch (error) {
    console.log(error);
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const deleteInvoiceById = async (id) => {
  try {
    const res = await axios.delete(`/invoice-report-delete/${id}`);
    return res;
  } catch (error) {
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const getInvoiceById = async (id) => {
  try {
    const res = await axios.get(`/view-invoice/${id}`);
    return res;
  } catch (error) {
    console.log(error);
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const invoiceCreatedDate = async () => {
  try {
    const res = await axios.get(`/invoice-created-date`);
    return res;
  } catch (error) {
    console.log(error);
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const allClients = async () => {
  try {
    const res = await axios.get(`/all-clients`);
    return res;
  } catch (error) {
    return error;
  }
};

export const changeStatus = async (id) => {
  try {
    const res = await axios.put(`/invoice-status-paid/${id}`);
    return res;
  } catch (error) {
    // console.log(error);
    // Swal.fire({
    //   width: "20em",
    //   height: "20em",
    //   position: "center",
    //   icon: "error",
    //   title: "Sorry...",
    //   text: ` ${error?.response?.data?.message}`,
    // });
    return error;
  }
};

export const sendMail = async (id) => {
  try {
    const res = await axios.post(`/send-mail-to-client/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const contractorData = async (q) => {
  q = q || "";
  try {
    const res = await axios.get(`/contractors-data?q=${q}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const clientData = async (q) => {
  q = q || "";
  try {
    const res = await axios.get(`/client-data?q=${q}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const reset_password = async (id, data) => {
  try {
    const res = await axios.put(`/reset-password/${id}`, data);
    return res;
  } catch (error) {
    Swal.fire({
      width: "20em",
      height: "20em",
      title: `${error?.response?.data?.message}`,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    });
  }
};

export const disable_user = async (id, data) => {
  try {
    const res = await axios.put(`/disable-user/${id}`, data);
    return res;
  } catch (error) {
    Swal.fire({
      width: "20em",
      height: "20em",
      title: `${error?.response?.data?.message}`,
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    });
  }
};

export const findUserById = async (id) => {
  try {
    const res = await axios.get(`/find-user-by-id/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const EditUserById = async (id, data) => {
  try {
    const res = await axios.put(`/edit-user/${id}`, data);
    return res;
  } catch (error) {
    return error;
  }
};

export const categoriesList = async (data) => {
  try {
    const res = await axios.get(`/timesheets-categories`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const projectList = async (data) => {
  try {
    const res = await axios.get(`/timesheets-project`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const taskList = async (data) => {
  try {
    const res = await axios.get(`/timesheets-task`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getNotify = async (id) => {
  try {
    const res = await axios.get(`/notify/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllNotification = async () => {
  try {
    const res = await axios.get(`/notify`);
    return res;
  } catch (error) {
    return error;
  }
};

export const acknowledge_notification = async (userId) => {
  try {
    const res = await axios.put(`/acknowledge-notification/${userId}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const not_acknowledge_notification = async (userId) => {
  try {
    const res = await axios.get(`/notification-for-user/${userId}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const openNotification = async (id) => {
  try {
    const res = await axios.put(`/notification-open/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const EditUserProfileById = async (id, data) => {
  try {
    const res = await axios.put(`/setting/profile/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteUser = async (id) => {
  try {
    const res = await axios.delete(`/delete-user/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteProfilePicture = async (id) => {
  try {
    const res = await axios.delete(`/delete-profile/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const updatePasswordById = async (id, data) => {
  try {
    const res = await axios.put(`/update-password-by-id/${id}`, data);
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteEvent = async (id) => {
  try {
    const res = await axios.delete(`/delete-event/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
