import React, { useEffect, useState } from "react";
import { getApproval, deleteById, allAdminApproval } from "../../Api";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "./Spinner";
import DataTable from "./DataTable";
import { useUserContext } from "../../context";

export default function TSheetAproval() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { user } = useUserContext();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [data]);
  const getData = async () => {
    if (user?.role === "Contractor") {
      try {
        const res = await getApproval(id);
        if (res) {
          setLoading(false);
          setData(res?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await allAdminApproval();
        if (res) {
          setLoading(false);
          setData(res?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleDelete = async (id) => {
    const res = await deleteById(id);
    if (res) {
      Swal.fire({
        width: "20em",
        height: "20em",
        position: "center",
        icon: "success",
        title: "Deleted successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        width: "20em",
        height: "20em",
        position: "center",
        icon: "success",
        title: "Something wents wrong?",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <div className="h-screen px-4 overflow-x-hidden overflow-y-auto">
      <DataTable data={data} handleDelete={handleDelete} />
      <Spinner loading={loading} setLoading={setLoading} />
    </div>
  );
}
