import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { allTimeSheetReports, deleteById } from "../../Api";
import Spinner from "./Spinner";
import DataList from "./DataList";
import DataTable from "./DataTable";
import * as XLSX from "xlsx";
import format from "date-fns/format";

export default function TimeSheetReports() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({
    contactor: "",
    category: "",
    startDate: "",
    endDate: "",
    project: "",
    ID: "",
    hour: "",
    rate: "",
    timesheetCreateAtStart: "",
    timesheetCreateAtEnd: "",
    task: "",
  });

  useEffect(() => {
    getTimeSheetReport();
    // eslint-disable-next-line
  }, [data, query]);

  const getTimeSheetReport = async () => {
    try {
      const res = await allTimeSheetReports(query);
      if (res?.status === 200) {
        setLoading(false);

        setData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteById(id);
    if (res) {
      Swal.fire({
        width: "20em",
        height: "20em",
        position: "center",
        icon: "success",
        title: "Deleted successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        width: "20em",
        height: "20em",
        position: "center",
        icon: "success",
        title: "Something wents wrong?",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const saveAsExcelFile = () => {
    const transformedData = data?.map((item) => {
      return {
        contractorName: item.name,
        timesheetName: item.timeSheetName,
        ApprovalDate: item.approvalDate,
        createdOn: format(new Date(item.createdAt), "MM/dd/yyyy"),
        status: item.status,
      };
    });
    // Convert JSON to 2D array
    const ws_data = [
      Object.keys(transformedData[0]),
      ...transformedData?.map((obj) => Object.values(obj)),
    ];

    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // save to file
    XLSX.writeFile(
      wb,
      `Timesheet_Reports_${format(new Date(), "MM/dd/yyyy")}.xlsx`
    );
  };

  return (
    <div className="space-y-10 w-full">
      <DataList setQuery={setQuery} query={query} />
      <DataTable data={data} handleDelete={handleDelete} />
      <Spinner loading={loading} setLoading={setLoading} />
      <div className="flex w-full lg:justify-start justify-center">
        <button
          onClick={saveAsExcelFile}
          className="px-8 rounded-lg border-none outline-none text-white font-semibold bg-green-400 py-2"
        >
          Save as .xlsx
        </button>
      </div>
    </div>
  );
}
