import React from "react";

import TeamMembers from "../components/TeamMembers/TeamMembers";

function TeamMembersPage() {
  return (
    <>
      <TeamMembers />
    </>
  );
}

export default TeamMembersPage;
