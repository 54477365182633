import React from "react";

import Career from "../components/careers/career";

function CareerPage() {
  return (
    <>
      <Career />
    </>
  );
}

export default CareerPage;
