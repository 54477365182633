import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import AddEventModal from "./AddEventModal";
import { allCalendarEvent } from "../../Api";
import DeleteEvent from "./DeleteEvent";
import { useUserContext } from "../../context";

function Calendar() {
  const [showModal, setShowModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const { user } = useUserContext();
  useEffect(() => {
    getEvents();
    // getBirthday();
    // eslint-disable-next-line
  }, []);
  const getEvents = async () => {
    const res = await allCalendarEvent();
    res?.data?.allevents?.forEach((item) => {
      item.end = incrementDate(item.end);
    });
    setEvents(res?.data?.allevents);
  };
  // const getBirthday = async () => {
  //   const res = await allUserBirthDays();
  //   if (res?.data?.success) {
  //     setBirthday(res?.data?.birthday);
  //   }
  // };

  // const modifiedArray = birthday
  //   ?.filter((item) => item.DOB.trim() !== "")
  //   ?.map((item) => {
  //     const monthAndDay = item.DOB.substring(5);
  //     // Get the current year
  //     const currentYear = new Date().getFullYear();
  //     // Construct the new date string
  //     const newDate = `${currentYear}-${monthAndDay}`;
  //     return {
  //       title: `${item.name}'s birthday`,
  //       start: newDate,
  //       end: newDate,
  //       color: "purple",
  //     };
  //   });
  // useEffect(() => {
  //   setData([...events, ...modifiedArray]);
  //   // eslint-disable-next-line
  // }, [events, birthday]);

  function incrementDate(dateStr) {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + 1);

    // Convert back to YYYY-MM-DD format
    // console.log(date.toISOString().split("T")[0]);
    return date.toISOString().split("T")[0];
  }

  const handleEventClick = async (clickInfo) => {
    if (user?.role === "Admin" || user?.role === "Super Admin") {
      setId(clickInfo.event._def.extendedProps._id);
      setTitle(clickInfo.event.title);
      setDeleteModal(true);
    }
  };

  return (
    <>
      <div className="mt-4">
        <div className="flex justify-end items-end w-full">
          <button
            className="w-24 h-9 bg-black text-white rounded-sm"
            onClick={() => setShowModal(true)}
          >
            Add Event
          </button>
        </div>

        <div className="mt-6 overflow-auto">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={events}
            headerToolbar={{
              left: "prev,next", // will normally be on the left. if RTL, will be on the right
              center: "title",
              end: "dayGridMonth,timeGridWeek,timeGridDay", // will normally be on the right. if RTL, will be on the left
            }}
            eventClick={handleEventClick}
          />
        </div>
      </div>
      <AddEventModal
        showModal={showModal}
        setShowModal={setShowModal}
        events={events}
        setEvents={setEvents}
      />
      <DeleteEvent
        id={id}
        setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        title={title}
      />
    </>
  );
}

export default Calendar;
