import React, { useEffect, useState } from "react";
import bell from "../../assests/bellIcon.svg";
import {
  allApproved,
  getApproval,
  allRejected,
  getNotify,
  openNotification,
  allAdminApproval,
  allAdminApproved,
  allAdminRejected,
  allDraft,
  getAllNotification,
  acknowledge_notification,
  not_acknowledge_notification,
} from "../../Api";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { UpRightArrowIcon } from "../iconComponent/Icon";
import { useUserContext } from "../../context";

export default function Home() {
  const [approved, setApproved] = useState();
  const [approval, setApproval] = useState();
  const [draft, setDraft] = useState();
  const [rejected, setRejected] = useState();
  const [open, setOpen] = useState(false);
  const [notification, setNotifications] = useState();
  const [allNotification, setAllNotifications] = useState();
  const [allNotificationCount, setAllNotificationsCount] = useState();

  const { user } = useUserContext();

  useEffect(() => {
    getApproved();
    getAllApproval();
    getRejected();
    getAllDraft();
    getNotification();
    getAllNotificationAdmin();
    getAllNotificationCount();
    // eslint-disable-next-line
  }, []);

  const getApproved = async () => {
    if (user?.role === "Admin" || user?.role === "Super Admin") {
      const res = await allAdminApproved();
      if (res) {
        setApproved(res?.data?.data);
      }
    } else {
      const res = await allApproved(user?.userId);
      if (res) {
        setApproved(res?.data?.data);
      }
    }
  };
  const getAllApproval = async () => {
    if (user?.role === "Admin" || user?.role === "Super Admin") {
      const res = await allAdminApproval();
      if (res) {
        setApproval(res?.data?.data);
      }
    } else {
      const res = await getApproval(user?.userId);
      if (res) {
        setApproval(res?.data?.data);
      }
    }
  };
  const getAllDraft = async () => {
    const res = await allDraft(user?.userId);
    if (res) {
      setDraft(res?.data?.data);
    }
  };

  const getRejected = async () => {
    if (user?.role === "Admin" || user?.role === "Super Admin") {
      const res = await allAdminRejected();
      if (res) {
        setRejected(res?.data?.data);
      }
    } else {
      const res = await allRejected(user?.userId);
      if (res) {
        setRejected(res?.data?.data);
      }
    }
  };

  const getNotification = async () => {
    const res = await getNotify(user?.userId);
    if (res) {
      setNotifications(res?.data?.data);
    }
  };

  const getAllNotificationAdmin = async () => {
    const res = await getAllNotification();
    if (res) {
      setAllNotifications(res?.data?.data);
    }
  };

  const getAllNotificationCount = async () => {
    const res = await not_acknowledge_notification(user?.userId);
    if (res) {
      setAllNotificationsCount(res?.data?.notifications);
    }
  };
  const count = notification?.filter((item, i) => item.notify.open === false);
  const handleOpen = async () => {
    await openNotification(user?.userId);
  };
  const handleOpenNotification = async () => {
    await acknowledge_notification(user?.userId);
  };
  const notEmpty = allNotification?.filter(
    (item) => item.notify.users.length !== 0
  );

  const notEmptyContractor = notification?.filter(
    (item) => item.notify.users.length !== 0
  );

  return (
    <>
      <div className="lg:w-full md:w-[700px] md:px-5">
        <div className="flex items-center pb-6 border-b lg:justify-between gap-2 lg:flex-row md:justify-between md:flex-row  flex-col justify-center my-2">
          <div className="lg:w-7/12 w-full px-4">
            <p className="font-medium text-sm lg:text-left md:text-left text-center">
              Welcome To Guddge Timesheet !
            </p>
          </div>
          {user?.role === "Admin" || user?.role === "Super Admin" ? (
            <>
              <div className="flex lg:w-0 w-full items-center justify-center h-11 relative">
                <div className=" h-[44px] absolute lg:right-0 flex items-center ml-2 lg:mt-0">
                  <button
                    onClick={() => {
                      setOpen(!open);
                      handleOpenNotification();
                    }}
                    className="outline-none relative ml-2 bg-[#34A2B2] flex justify-center items-center w-[56px] h-[44px] rounded-md  "
                  >
                    <img
                      src={bell}
                      alt="bell-icon"
                      className="animate-pulse absolute"
                    />
                    <div
                      className={`absolute h-5 -top-2 -right-2 ${
                        allNotificationCount?.length !== 0 &&
                        allNotificationCount?.length !== undefined
                          ? "block"
                          : "hidden"
                      } w-5 rounded-full text-white bg-red-600 flex justify-center items-center`}
                    >
                      <p className="text-xs">{allNotificationCount?.length}</p>
                    </div>
                  </button>
                </div>
                <div
                  className={`absolute ${
                    open ? "block" : "hidden"
                  } top-14 py-2 z-20 border border-gray-300 lg:-left-56 w-56 shadow-lg bg-white overflow-y-auto rounded-md h-80`}
                >
                  <>
                    {notEmpty?.length > 0 ? (
                      <>
                        {notEmpty?.map((obj, i) => (
                          <Link to={`/timesheets/${obj?.notify.timesheetId}`}>
                            <div
                              className="w-full p-2 border-y cursor-pointer"
                              title="Click to open timesheet"
                              key={obj?.id}
                            >
                              <p className="text-left text-gray-500 font-normal text-sm">
                                {obj?.notify.message}
                              </p>
                              <p className="text-xs text-right text-gray-500">
                                {format(new Date(obj?.createdAt), "MM/dd/yyyy")}
                              </p>
                            </div>
                          </Link>
                        ))}
                      </>
                    ) : (
                      <div
                        className="w-full p-2 cursor-pointer"
                        title="Click to open timesheet"
                      >
                        <p className="text-xs text-center text-gray-500">
                          Notifications
                        </p>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex lg:w-0 w-full items-center justify-center h-11 relative">
                <div className=" h-[44px] absolute lg:right-0 flex items-center ml-2 lg:mt-0">
                  <button
                    onClick={() => {
                      setOpen(!open);
                      handleOpen();
                    }}
                    className="outline-none relative ml-2 bg-[#34A2B2] flex justify-center items-center w-[56px] h-[44px] rounded-md  "
                  >
                    <img
                      src={bell}
                      alt="bell-icon"
                      className="animate-pulse absolute"
                    />
                    <div
                      className={`absolute h-5 -top-2 -right-2 ${
                        count?.length !== 0 && count?.length !== undefined
                          ? "block"
                          : "hidden"
                      } w-5 rounded-full text-white bg-red-600 flex justify-center items-center`}
                    >
                      <p className="text-xs">{count?.length}</p>
                    </div>
                  </button>
                </div>
                <div
                  className={`absolute ${
                    open ? "block" : "hidden"
                  } top-14 py-2 z-20 border border-gray-300 lg:-left-56 w-56 shadow-lg bg-white overflow-y-auto rounded-md h-80`}
                >
                  <>
                    {notEmptyContractor?.length > 0 ? (
                      <>
                        {notEmptyContractor?.map((obj, i) => (
                          <Link to={`/timesheets/${obj?.notify.timesheetId}`}>
                            <div
                              className="w-full p-2 border-y cursor-pointer"
                              title="Click to open timesheet"
                              key={obj?.id}
                            >
                              <p className="text-left text-gray-500 font-normal text-sm">
                                {obj?.notify.text}
                              </p>
                              <p className="text-xs text-right text-gray-500">
                                {format(new Date(obj?.createdAt), "MM/dd/yyyy")}
                              </p>
                            </div>
                          </Link>
                        ))}
                      </>
                    ) : (
                      <div
                        className="w-full p-2 cursor-pointer"
                        title="Click to open timesheet"
                      >
                        <p className="text-xs text-center text-gray-500">
                          Notifications
                        </p>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="w-full border-b pb-4 gap-x-3 pt-6 flex lg:flex-row md:flex-row flex-col justify-between  items-center ">
          {user?.role === "Admin" || user?.role === "Super Admin" ? (
            <div className=" md:w-2/4 w-full bg-[#49A147] flex border justify-center rounded-lg h-[109px] lg:mt-0 md:mt-0 mt-4">
              <Link
                to={`/timesheets/approval/${user?.userId}`}
                className="w-full"
              >
                <div className="w-full xl:px-6 px-4 h-full flex flex-col items-center justify-center">
                  <p className="w-full text-white text-xs font-medium">
                    Timesheet to Review
                  </p>
                  <div className="flex w-full items-center justify-between mt-1 text-white cursor-pointer">
                    <div className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-[#06FF00]"></div>
                      <p className="text-white text-xs font-semibold ml-1">
                        {approval?.length ? approval.length : "0"}
                      </p>
                    </div>

                    <UpRightArrowIcon />
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            <div className=" md:w-2/4 w-full bg-[#49A147] flex border justify-center rounded-lg h-[109px] lg:mt-0 md:mt-0 mt-4">
              <Link to={`/timesheets/draft/${user?.userId}`} className="w-full">
                <div className="w-full xl:px-6 px-4 h-full flex flex-col items-center justify-center">
                  <p className="w-full text-white text-xs font-medium">
                    Timesheet Draft
                  </p>
                  <div className="flex w-full items-center justify-between mt-1 text-white cursor-pointer">
                    <div className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-[#06FF00]"></div>
                      <p className="text-white text-xs font-semibold ml-1">
                        {draft?.length ? draft.length : "0"}
                      </p>
                    </div>

                    <UpRightArrowIcon />
                  </div>
                </div>
              </Link>
            </div>
          )}

          <div className=" bg-[#EDFFED] md:w-2/4 w-full flex justify-center rounded-lg h-[109px] lg:mt-0 md:mt-0 mt-4">
            <Link
              to={`/timesheets/approved/${user?.userId}`}
              className="w-full"
            >
              <div className="h-full w-full xl:px-6 px-4 flex flex-col items-center justify-center">
                <p className="w-full text-xs font-medium">Timesheet Approved</p>
                <div className="flex w-full items-center justify-between mt-1 text-black cursor-pointer">
                  <p className="text-[#169AA3] text-xs font-semibold drop-shadow-2xl">
                    {approved?.length ? approved?.length : "0"}
                  </p>

                  <UpRightArrowIcon />
                </div>
              </div>
            </Link>
          </div>
          <div className=" bg-[#FFF8E7] md:w-2/4 w-full flex justify-center rounded-lg h-[109px] lg:mt-0 md:mt-0 mt-4">
            <Link
              to={`/timesheets/rejected/${user?.userId}`}
              className="w-full"
            >
              <div className="h-full w-full xl:px-6 px-4 flex flex-col items-center justify-center">
                <p className="w-full text-xs font-medium">Timesheet Rejected</p>
                <div className="flex w-full items-center justify-between mt-1 text-black cursor-pointer">
                  <p className="text-[#795706] text-xs font-semibold drop-shadow-2xl">
                    {rejected?.length ? rejected?.length : "0"}
                  </p>

                  <UpRightArrowIcon />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
