import React, { useState, useEffect } from "react";
import search from "../../assests/searchIcon.svg";
import plus from "../../assests/plusIcon.svg";
import edit from "../../assests/editIcon.svg";
import ContractorModal from "./ContractorModal";
import { contractorData, disable_user } from "../../Api";
import EditAdminModal from "./EditAdminModal";
import { TrashIcon } from "../iconComponent/Icon";
import DeleteModal from "./DeleteModal";
import { useUserContext } from "../../context";

export default function Contractors() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [id, setId] = useState();
  const [query, setQuery] = useState();
  const { user } = useUserContext();

  useEffect(() => {
    getContractorData();
    // eslint-disable-next-line
  }, [data, query]);

  const getContractorData = async () => {
    const res = await contractorData(query);
    if (res?.data?.success) {
      setData(res?.data?.contractor);
    }
  };

  const handleDisable = async (e, value) => {
    await disable_user(value, { disable: e.target.checked });
  };

  return (
    <>
      <div className="w-full md:px-5">
        <div className="flex items-center lg:justify-between lg:flex-row md:justify-between md:flex-row  flex-col justify-center my-2">
          <div className="w-7/12 px-4">
            <h1 className="font-semibold text-lg lg:text-left md:text-left text-center">
              All contractors
            </h1>
          </div>
          <div className="w-[233px] md:h-[52px] h-10 border border-[#A1A1A1] flex items-center rounded-lg lg:mt-0 mt-5">
            <img src={search} alt="search" className="w-5 h-5 ml-3" />
            <input
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search User by name or email"
              className="placeholder-[#A1A1A1] text-sm w-full pl-2 outline-none text-[#A1A1A1]"
            />
          </div>
        </div>
        <div className=" flex items-center lg:flex-row lg:justify-between md:flex-row md:justify-between justify-center flex-col  my-7 px-4">
          <h1 className="font-semibold text-lg">
            Total Contractors <span>{data ? data.length : "0"}</span>
          </h1>
          {user?.role === "Super Admin" ? (
            <button
              className="w-auto px-2 h-11 bg-black rounded-lg flex items-center justify-center lg:mt-0 mt-3"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img src={plus} alt="create" />
              <p className="text-white text-xs font-normal pl-1">
                Create an Contractor Account
              </p>
            </button>
          ) : null}
        </div>
        <div className="h-screen overflow-y-auto px-4">
          {data?.map((obj, i) => (
            <div key={obj?.id} className="py-3 ">
              <hr className="mb-6" />

              <div className="lg:flex md:flex items-center justify-between">
                <div className="flex">
                  <div>
                    <p className="text-base font-normal text-gray-500">Name</p>
                    <p className="text-base font-normal text-gray-500">Email</p>
                    {/* <p className="text-base font-normal text-gray-500">
                      License key
                    </p> */}
                    <p className="text-base font-normal text-gray-500">
                      Contact
                    </p>
                    <p className="text-base font-normal text-gray-500">
                      Hourly Rate
                    </p>
                  </div>
                  <div className="ml-5">
                    <p className=" text-base font-medium">
                      {obj.name ? obj.name : "no name"}
                    </p>
                    <p className=" text-base font-medium">
                      {obj.email ? obj.email : "no email"}
                    </p>
                    {/* {obj.identificationNumber ? (
                      <p className=" text-base font-medium">
                        {obj.identificationNumber}
                      </p>
                    ) : (
                      <p className=" text-base text-gray-500 font-normal">
                        'Identification Number required'
                      </p>
                    )} */}

                    {obj.phoneNumber ? (
                      <p className=" text-base font-medium">
                        +{obj.phoneNumber}
                      </p>
                    ) : (
                      <p className=" text-base text-gray-500 font-normal">
                        'Phone Number required'
                      </p>
                    )}

                    {obj.contractorRate ? (
                      <p className=" text-base font-medium">
                        ${obj.contractorRate}
                      </p>
                    ) : (
                      <p className=" text-base text-gray-500 font-normal">
                        'Contractor Rate required'
                      </p>
                    )}
                  </div>
                </div>
                {user?.role === "Super Admin" ? (
                  <div className=" lg:mt-0 md:mt-0 mt-5 flex lg:items-end md:items-end lg:flex-col md:flex-col lg:justify-evenly md:justify-evenly  justify-between  lg:h-32 md:h-32">
                    <div
                      onClick={() => {
                        setId(obj?._id);
                        setEditModal(true);
                      }}
                      className="flex cursor-pointer justify-center items-center"
                    >
                      <p className="font-normal text-xs">Edit</p>
                      <img
                        src={edit}
                        alt="edit"
                        className="h-5 w-5 ml-2 lg:mr-0 md:mr-0 mr-5"
                      />
                    </div>

                    <div className="flex justify-center items-center">
                      <span className="font-normal text-xs">
                        Disable Contractor
                      </span>
                      <label className="relative inline-flex items-center cursor-pointer ml-3 -z-0">
                        <input
                          type="checkbox"
                          checked={obj.disable}
                          onChange={(e) => handleDisable(e, obj._id)}
                          className="sr-only peer "
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                      </label>
                    </div>

                    <div
                      onClick={() => {
                        setDeleteModal(true);
                        setDeleteId(obj._id);
                      }}
                      className="cursor-pointer"
                    >
                      <TrashIcon />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
          <EditAdminModal
            setEditModal={setEditModal}
            editModal={editModal}
            id={id}
          />
          <ContractorModal setShowModal={setShowModal} showModal={showModal} />
          <DeleteModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            deleteId={deleteId}
          />
        </div>
      </div>
    </>
  );
}
