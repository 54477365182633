import React from "react";
import cancel from "../../assests/cancelIcon.svg";
import { deleteEvent } from "../../Api";
import Swal from "sweetalert2";

export default function DeleteEvent({
  setDeleteModal,
  id,
  deleteModal,
  title,
}) {
  const handleDeleteEvent = async () => {
    console.log(id);
    try {
      const res = await deleteEvent(id);
      if (res) {
        setDeleteModal(false);
        Swal.fire({
          width: "20em",
          height: "20em",
          position: "center",
          icon: "success",
          title: `${res?.data?.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      window.location.reload();
    } catch (error) {
      Swal.fire({
        width: "20em",
        height: "20em",
        title: `${error?.response?.data.message}`,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    }
  };
  return (
    <>
      {deleteModal ? (
        <>
          <div className=" overflow-x-hidden flex items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative lg:w-[538px] md:w-[400px] my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none pb-5">
                {/*header*/}
                <div className="flex items-start justify-between p-5">
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setDeleteModal(false)}
                  >
                    <img src={cancel} alt="cancel" />
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-6 flex space-y-5 flex-col justify-center items-center">
                  <div>
                    <h1 className="font-medium text-xl">
                      Are you sure you want to delete this "{title}" event?
                    </h1>
                  </div>
                  <div className="flex space-x-4">
                    <button
                      className="w-20 h-[44px] bg-red-600 rounded-lg flex items-center justify-center lg:mt-0 mt-3"
                      onClick={() => {
                        handleDeleteEvent();
                      }}
                    >
                      <p className="text-white text-xs font-normal pl-1">Yes</p>
                    </button>
                    <button
                      className="w-20 h-[44px] bg-green-600 rounded-lg flex items-center justify-center lg:mt-0 mt-3"
                      onClick={() => {
                        setDeleteModal(false);
                      }}
                    >
                      <p className="text-white text-xs font-normal pl-1">No</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
