import React, { useState, useEffect } from "react";
// import * as Yup from "yup";
import { Formik, Field, Form, FieldArray } from "formik";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { getDataById, generateInvioce, sendMail, clientRates } from "../../Api";
import Swal from "sweetalert2";
import { useUserContext } from "../../context";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../timeSheet/Spinner";

// const tableSchema = Yup.object().shape({
//   miscellaneous: Yup.array().of(
//     Yup.object().shape({
//       cost: Yup.string().matches(/^\d+$/, "cost must be digits").nullable(),
//     })
//   ),
// });

export default function GenerateInvoice() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [flyer, setFlyer] = useState(false);
  const [rate, setRate] = useState();
  const [client, setClient] = useState();

  const navigate = useNavigate();
  const { user } = useUserContext();
  const { id } = useParams();

  useEffect(() => {
    getData();
    getClients();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    const res = await getDataById(id);

    if (res?.status === 200) {
      setLoading(false);
      setData(res?.data?.data);
    }
  };

  const modifiedData = data?.dataSheet?.map((obj, i) => {
    const date = obj?.changeDate;
    const regex = /^-?\d+(\.\d+)?$/;

    const month = format(new Date(date), "MMMM", { locale: enUS });
    return {
      ...obj,
      changeDate: `${month}`,
      rate: `${
        rate?.clientRate
          ? regex.test(obj.hour)
            ? obj.hour * rate?.clientRate
            : obj.hour
          : "0"
      }`,
    };
  });
  const total = modifiedData?.reduce((accumulator, obj) => {
    const rate = parseFloat(obj.rate);
    const regex = /^-?\d+(\.\d+)?$/;
    if (!isNaN(rate) && regex.test(rate) && !/\D/.test(obj.rate)) {
      return accumulator + rate;
    }
    return accumulator;
  }, 0);
  const newData = { dataSheet: modifiedData };

  const getClients = async () => {
    const res = await clientRates();
    if (res?.data?.success) {
      setLoading(false);
      setClient(res?.data?.clients);
    }
  };

  return (
    <div className="flex flex-col w-full px-5 md:h-[135vh] lg:h-[120vh] h-auto">
      <div className="w-full h-full">
        <Formik
          // validationSchema={tableSchema}
          initialValues={{
            user: `${user?.userId}`,
            name: `${user?.name}`,
            timeSheetId: `${data?._id}`,
            clientId: `${rate?._id}`,
            clientRate: `${rate?.clientRate}`,
            clientName: `${rate?.name}`,
            status: "unpaid",
            timeSheetName: `${data?.timeSheetName}`,
            sharedDate: `${format(new Date(), "MM/dd/yyyy")}`,
            total: `${total ? total : "0"}`,
            ...newData,
          }}
          onSubmit={async (values) => {
            if (rate) {
              try {
                const res = await generateInvioce(values);
                if (res?.data?.success) {
                  const val = res?.data?.dataTable[0]?._id;
                  await sendMail(val);
                  navigate("/home");
                  Swal.fire({
                    width: "20em",
                    height: "20em",
                    position: "center",
                    icon: "success",
                    title: `${res?.data?.message}`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              } catch (error) {
                Swal.fire({
                  width: "20em",
                  height: "20em",
                  title: `Something wents wrong try again`,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                });
              }
            } else {
              Swal.fire({
                width: "20em",
                height: "20em",
                title: `Please Select Client Rate First`,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
            }
          }}
          enableReinitialize
        >
          {({ isSubmitting, values, setValues }) => (
            <Form className="w-full md:h-screen h-auto">
              <div className="md:w-2/4 w-full flex justify-end py-1">
                <div className="w-full z-10">
                  <h1 className="text-[#7A7A79]">Select Client Rates</h1>
                  <div className="relative w-full mt-0.5 border border-1 border-[#B8B7B6]">
                    <button
                      id="dropdownDelayButton"
                      data-dropdown-toggle="dropdownDelay"
                      data-dropdown-delay="500"
                      data-dropdown-trigger="hover"
                      className="text-black w-full border-[#B8B7B6] justify-between outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1.5 text-center inline-flex items-center"
                      type="button"
                      onClick={() => setFlyer(!flyer)}
                    >
                      <p className="text-[#7A7A79]">
                        {rate ? rate?.name : "Select"}
                      </p>
                      <svg
                        className="w-4 h-4 ml-2"
                        aria-hidden="true"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </button>

                    <div
                      id="dropdownDelay"
                      className={` ${
                        flyer ? "block" : "hidden"
                      } z-40 absolute w-full border bg-white divide-y divide-gray-100 shadow dark:bg-gray-700`}
                    >
                      <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDelayButton"
                      >
                        {loading ? (
                          <Spinner />
                        ) : (
                          <>
                            {client?.map((obj, i) => (
                              <div className="cursor-pointer">
                                {obj?.clientRate ? (
                                  <>
                                    <li
                                      key={obj?._id}
                                      onClick={() => {
                                        setRate(obj);
                                        setFlyer(false);
                                      }}
                                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                    >
                                      <div className="h-full w-full cursor-pointer flex justify-between">
                                        <p>{obj.name}</p>
                                      </div>
                                    </li>
                                  </>
                                ) : null}
                              </div>
                            ))}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="w-full ">
                  <div className="flex w-full flex-col">
                    <div className="sm:-mx-6 lg:-mx-8">
                      <div className=" py-2 sm:px-6 lg:px-8 ">
                        <FieldArray name="dataSheet">
                          {({ insert, remove, push }) => (
                            <div className=" rounded-lg h-[70vh] px-2 overflow-y-auto">
                              <table className="table-auto text-left text-sm font-light w-full">
                                <thead className="border-b bg-[#E3E8EF] font-medium rounded-lg dark:border-neutral-500">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                                    >
                                      Month
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                    >
                                      ID
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                    >
                                      Invoice Category
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                    >
                                      Project
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                    >
                                      Task
                                    </th>
                                    {user?.role === "Admin" ||
                                    user?.role === "Super Admin" ? (
                                      <th
                                        scope="col"
                                        className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                      >
                                        Rate
                                      </th>
                                    ) : null}

                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                    >
                                      Hour
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                                    >
                                      Comments (include risks, issues, details
                                      as applicable)
                                    </th>
                                  </tr>
                                </thead>
                                {values?.dataSheet?.length > 0 &&
                                  values?.dataSheet?.map((dataSheet, index) => (
                                    <tbody key={index} className="w-full">
                                      {loading ? (
                                        <tr className="h-10 w-full">
                                          <Spinner />
                                        </tr>
                                      ) : (
                                        <>
                                          <tr className="border-b dark:border-neutral-500">
                                            <td
                                              width="20%"
                                              className="whitespace-nowrap text-center "
                                            >
                                              <Field
                                                disabled={true}
                                                name={`dataSheet.${index}.changeDate`}
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="12-06-2021"
                                              />
                                            </td>
                                            <td className="whitespace-nowrap text-center ">
                                              <Field
                                                name={`dataSheet.${index}.ID`}
                                                value={
                                                  values?.dataSheet[index]?.ID
                                                }
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="126106"
                                              />
                                            </td>
                                            <td className="whitespace-nowrap text-center ">
                                              <Field
                                                name={`dataSheet.${index}.invoiceCategory`}
                                                value={
                                                  values?.dataSheet[index]
                                                    ?.invoiceCategory
                                                }
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="DFAS"
                                              />
                                            </td>
                                            <td className="whitespace-nowrap text-center ">
                                              <Field
                                                name={`dataSheet.${index}.project`}
                                                value={
                                                  values?.dataSheet[index]
                                                    ?.project
                                                }
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="Data Foundation"
                                              />
                                            </td>
                                            <td className="whitespace-nowrap text-center ">
                                              <Field
                                                name={`dataSheet.${index}.task`}
                                                value={
                                                  values?.dataSheet[index]?.task
                                                }
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="Added a new View"
                                              />
                                            </td>
                                            {user?.role === "Admin" ||
                                            user?.role === "Super Admin" ? (
                                              <td className="whitespace-nowrap text-center ">
                                                <Field
                                                  name={`dataSheet.${index}.rate`}
                                                  value={
                                                    values?.dataSheet[index]
                                                      ?.rate
                                                  }
                                                  className="h-full border-none outline-none w-full px-6 py-4"
                                                  // placeholder="0"
                                                />
                                              </td>
                                            ) : null}

                                            <td className="whitespace-nowrap text-center ">
                                              <Field
                                                name={`dataSheet.${index}.hour`}
                                                value={
                                                  values?.dataSheet[index]?.hour
                                                }
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="0"
                                              />
                                            </td>
                                            <td className="whitespace-nowrap text-center">
                                              <Field
                                                name={`dataSheet.${index}.comments`}
                                                value={
                                                  values?.dataSheet[index]
                                                    ?.comments
                                                }
                                                className="h-full border-none outline-none w-full px-6 py-4"
                                                // placeholder="Include October hours"
                                              />
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                    </tbody>
                                  ))}
                              </table>
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="md:my-5 my-1">
                  <div className="flex justify-end items-center w-full">
                    <div className="w-20 md:py-3 py-2">
                      <label className="text-base font-medium">Total</label>
                      <Field
                        name={`values.total`}
                        value={values?.total}
                        className="h-full border rounded-lg outline-none w-full px-4 py-2"
                      />
                    </div>
                  </div>
                </div>

                <div className="pb-7 flex flex-col gap-x-3 mt-14 lg:flex lg:flex-row md:flex-row  md:flex lg:justify-center md:justify-end justify-center items-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="font-normal h-11 w-36 bg-black text-white rounded flex items-center gap-x-1 justify-center"
                  >
                    <p>Share with client</p>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
