import React from 'react'


import  Portfolio from "../components/Portfolio/Portfolio"




function  PortfolioPage() {
  return (
    <>

<Portfolio></Portfolio>

    </>
    
  )
}

export default PortfolioPage