import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import NavBar from "./components/HomePageComponents/NavBar/NabBar";
import HomePage from "./pages/HomePage";
import ContactUsPage from "./pages/ContactUsPage";
import AboutPage from "./pages/AboutPage";
import ArticlePage from "./pages/ArticlePage";
import ServicePage from "./pages/ServicePage";
import ClientPage from "./pages/ClientPage";
import PortfolioPage from "./pages/PortfolioPage";
import CareerPage from "./pages/CareerPage";
import TeamMembersPage from "./pages/TeamMembersPage";
import ViewArticle from "./components/Article/ViewArticle";
import useDarkSide from "./components/HomePageComponents/NavBar/useDarkSide";
import Footer from "./components/HomePageComponents/Footer/Footer";
import "./App.css";
import {
  Home,
  LoginByEmail,
  RegisterByEmail,
  Contractors,
  Clients,
  GenerateInvoice,
  Projects,
  ViewProject,
  Rates,
  Admin,
  ProfileSetting,
  Setting,
  TimeSheet,
  TSheetAproval,
  TSheetAproved,
  TsheetDraft,
  TimeViewSheets,
  ViewInvoice,
  EditViewProjectTable,
  TSheetRejected,
  TimeSheetReports,
  CalenderPage,
  InvoiceReport,
  View,
  ForgetPassword,
  UpdatePassword,
} from "./components/timeSheet";
import ProtectedRouted from "./components/timeSheet/ProtectedRoutes";
import TimeSheetPage from "./pages/TimeSheetPage";
import ErrorMessage from "./components/ErrorMessage";
import { UserContextProvider } from "./context";

function App() {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkMode, setDarkMode] = useState(
    colorTheme === "dark" ? true : false
  );

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkMode(checked);
  };

  return (
    <>
      <UserContextProvider>
        <NavBar
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          colorTheme={colorTheme}
          setTheme={setTheme}
          toggleDarkMode={toggleDarkMode}
        />
        <Routes>
          <Route
            path="/"
            element={
              <HomePage
                darkMode={darkMode}
                setDarkMode={setDarkMode}
                colorTheme={colorTheme}
                setTheme={setTheme}
                toggleDarkMode={toggleDarkMode}
              />
            }
          />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/article" element={<ArticlePage />} />
          <Route path="/career" element={<CareerPage />} />
          <Route path="/team-members" element={<TeamMembersPage />} />
          <Route path="/article-view" element={<ViewArticle />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/client" element={<ClientPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/forget-password/:token" element={<UpdatePassword />} />

          {/* <Route path="/timesheet" element={<LoginBymsOffice />} /> */}
          <Route path="/login" element={<LoginByEmail />} />
          <Route path="/signup" element={<RegisterByEmail />} />
          <Route path="/" element={<ProtectedRouted />}>
            <Route path="/" element={<TimeSheetPage />}>
              <Route index element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="admin" element={<Admin />} />
              <Route path="contractors" element={<Contractors />} />
              <Route path="clients" element={<Clients />} />
              <Route path="projects" element={<Projects />} />
              <Route path="viewproject" element={<ViewProject />} />
              <Route path="projects" element={<Projects />} />
              <Route path="rates" element={<Rates />} />
              <Route
                path="generate-invoice/:id"
                element={<GenerateInvoice />}
              />
              <Route path="invoice-reports/:id" element={<InvoiceReport />} />
              <Route
                path="timesheets-reports/:id"
                element={<TimeSheetReports />}
              />
              <Route
                path="timesheets-reports/:id?contractor=&status=&date="
                element={<TimeSheetReports />}
              />
              <Route path="timesheets" element={<TimeSheet />}>
                {/* <Route index element={<TSheetAproval />} /> */}
                <Route path="approval/:id" element={<TSheetAproval />} />
                <Route path=":id" element={<View />} />
                <Route path="approved/:id" element={<TSheetAproved />} />
                <Route path="rejected/:id" element={<TSheetRejected />} />
                <Route path="draft/:id" element={<TsheetDraft />} />
              </Route>
              <Route path="calender" element={<CalenderPage />} />
              <Route path="view-time-sheets" element={<TimeViewSheets />} />
              <Route
                path="view-time-sheets/:id"
                element={<EditViewProjectTable />}
              />
              <Route path="view-invoice/:id" element={<ViewInvoice />} />
              <Route path="setting" element={<Setting />} />
              <Route path="profile-setting" element={<ProfileSetting />} />
            </Route>
          </Route>
          <Route path="*" element={<ErrorMessage />} />
        </Routes>
        <Footer />
      </UserContextProvider>
    </>
  );
}
export default App;
