import React from 'react'


import Client from "../components/ClientPageComponent/Client"



function ArticlePage() {
  return (
    <>

<Client></Client>

    </>
    
  )
}

export default ArticlePage