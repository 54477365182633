import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useParams, useNavigate } from "react-router-dom";
import { getDataSheetById, getApprovedById, allCalendarEvent } from "../../Api";
import Swal from "sweetalert2";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
import RejectModal from "./RejectModal";
import { useUserContext } from "../../context";
import { format } from "date-fns";
import Spinner from "./Spinner";

export default function View() {
  const [data, setData] = useState();
  const [isCliked, setIsClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [val, setVal] = useState();

  const navigation = useNavigate();
  const { user } = useUserContext();
  const { id } = useParams();

  useEffect(() => {
    getDataById();
    getEvents();
    // eslint-disable-next-line
  }, []);

  const getDataById = async () => {
    try {
      const res = await getDataSheetById(id);
      if (res?.status) {
        setLoading(false);
        setData(res?.data?.data);
        setVal(res?.data?.data);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleApproval = async (item) => {
    const newDate = format(new Date(), "MM/dd/yyyy");

    try {
      const res = await getApprovedById(id, {
        ...data,
        status: "Approved",
        approvalDate: `${newDate}`,
        approvedBy: `${user?.name}`,
      });

      if (res) {
        navigation(`/home`);
        Swal.fire({
          width: "16em",
          height: "16em",
          position: "center",
          icon: "success",
          title: `${res?.data?.data}`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleNeedApproval = async () => {
    try {
      const res = await getApprovedById(id, {
        ...data,
        status: "Need approval",
      });

      if (res) {
        navigation(`/home`);
        Swal.fire({
          width: "16em",
          height: "16em",
          position: "center",
          icon: "success",
          title: `${res?.data?.data}`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleDate = (value) => {
    const regex = /^\d+(\.\d+)?$/;
    const isValid = regex.test(value);
    if (isValid) {
      const date = format(
        new Date(Math.round((value - 25569) * 86400 * 1000)),
        "MM/dd/yyyy"
      );
      return date;
    } else {
      return value;
    }
  };
  const getEvents = async () => {
    const res = await allCalendarEvent();
    setEvents(res?.data?.allevents);
  };
  const generateDateList = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dates = [];

    while (startDate <= endDate) {
      dates.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }

    return dates;
  };

  const expandedEvents = [];

  events?.forEach((event) => {
    const dates = generateDateList(event.start, event.end);
    dates.forEach((date) => {
      const formattedDate = date.toISOString().split("T")[0]; // Convert the Date object back to "YYYY-MM-DD" format
      const newEvent = {
        ...event,
        start: format(new Date(formattedDate), "MM/dd/yyyy"),
        end: formattedDate,
      };
      expandedEvents.push(newEvent);
    });
  });

  const containsNA = (obj) => {
    return Object.values(obj).some((value) => value === "N/A");
  };
  const getHighlightedArray = () => {
    return data?.dataSheet?.map((obj1) => {
      if (
        expandedEvents.some((obj2) => obj1.changeDate.trim() === obj2.start) ||
        containsNA(obj1)
      ) {
        return { ...obj1, color: "bg-red-600" }; // Replace 'desired-color' with your color
      }
      return obj1;
    });
  };

  const saveAsExcelFile = () => {
    // Convert JSON to 2D array
    const ws_data = [
      Object.keys(data?.dataSheet[0]),
      ...data?.dataSheet.map((obj) => Object.values(obj)),
    ];

    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // save to file
    XLSX.writeFile(wb, `${data?.timeSheetName}.xlsx`);
  };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="">
            <div className="flex md:flex-row flex-col items-center gap-x-6">
              <h1 className="font-semibold text-base text-[#075A66] py-2">
                Timesheet Name: {data?.timeSheetName}
              </h1>
              <h1 className="font-semibold text-base text-[#075A66] py-2">
                Created Date: {data?.currentDate}
              </h1>
            </div>
            <div className="flex flex-col py-3">
              <div className="sm:-mx-6 lg:-mx-8">
                <div className=" py-2 sm:px-6 lg:px-8 ">
                  <div className="rounded-lg border h-[70vh] overflow-y-auto">
                    <table
                      className="table-auto text-left text-sm font-light w-full"
                      id="table-to-xls"
                    >
                      <thead className="border-b bg-[#E3E8EF] font-medium rounded-lg dark:border-neutral-500">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                          >
                            Changes Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                          >
                            ID
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                          >
                            INVOICE CATEGORIES
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                          >
                            PROJECT
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                          >
                            TASK
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                          >
                            HOUR
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                          >
                            COMMENTS (include risks, issues, details as
                            applicable)
                          </th>
                        </tr>
                      </thead>
                      {getHighlightedArray().map((obj, index) => (
                        <tbody key={obj?._id} className="w-full">
                          <>
                            <tr className="border-b dark:border-neutral-500">
                              <td
                                width="20%"
                                className={`whitespace-nowrap text-center ${
                                  obj?.color ? "bg-green-500 text-white" : ""
                                }`}
                              >
                                {handleDate(obj.changeDate, index)}
                              </td>
                              <td
                                className={`whitespace-nowrap text-center ${
                                  obj?.color ? "bg-green-500 text-white" : ""
                                }`}
                              >
                                {obj?.ID}
                              </td>
                              <td
                                className={`whitespace-nowrap text-center ${
                                  obj?.color ? "bg-green-500 text-white" : ""
                                }`}
                              >
                                {obj?.invoiceCategory}
                              </td>
                              <td
                                className={`whitespace-nowrap text-center ${
                                  obj?.color ? "bg-green-500 text-white" : ""
                                }`}
                              >
                                {obj?.project}
                              </td>
                              <td
                                className={`whitespace-nowrap text-center ${
                                  obj?.color ? "bg-green-500 text-white" : ""
                                }`}
                              >
                                {obj?.task}
                              </td>
                              <td
                                className={`whitespace-nowrap text-center ${
                                  obj?.color ? "bg-green-500 text-white" : ""
                                }`}
                              >
                                {obj?.hour}
                              </td>
                              <td
                                className={`whitespace-nowrap text-center ${
                                  obj?.color ? "bg-green-500 text-white" : ""
                                }`}
                              >
                                {obj?.comments}
                              </td>
                            </tr>
                          </>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 ">
            <h1 className="text-base font-semibold">Miscellaneous expenses</h1>
            <div className="flex">
              <p className="p-2 text-base font-medium">
                Cost:
                <span className="text-sm text-gray-500">
                  ${data?.miscellaneous[0]?.cost}
                </span>
              </p>
              <p className=" p-2 text-base font-medium">
                Reason:
                <span className="text-sm text-gray-500">
                  {data?.miscellaneous[0]?.reason}
                </span>
              </p>
              <p className="p-2 text-base font-medium">
                Date:
                <span className="text-sm text-gray-500">
                  {data?.miscellaneous[0]?.date
                    ? `${format(
                        new Date(data?.miscellaneous[0]?.date),
                        "MM/dd/yyyy"
                      )} `
                    : null}
                </span>
              </p>
            </div>
          </div>

          {data?.desc ? (
            <p className="mt-5 p-2 text-base font-medium">Note: {data?.desc}</p>
          ) : null}

          <div className="flex w-full justify-center items-center gap-x-6 mt-5">
            <button
              onClick={saveAsExcelFile}
              className="px-8 rounded-lg border-none outline-none text-white font-semibold bg-green-400 py-2"
            >
              Save as .xlsx
            </button>
            {(user?.role === "Admin" || user?.role === "Super Admin") &&
            val?.status !== "Rejected" ? (
              <>
                {val?.status !== "Approved" ? (
                  <>
                    <button
                      onClick={() => {
                        setShowModal(true);
                        setIsClicked(true);
                      }}
                      disabled={isCliked}
                      className="h-[44px] w-[72px] outline-none border rounded-md text-xs font-normal border-black"
                    >
                      Reject
                    </button>
                    <button
                      onClick={() => {
                        handleApproval(data?.user);
                        setIsClicked(true);
                      }}
                      disabled={isCliked}
                      className="h-[44px] w-[81px] outline-none bg-[#5BBE0D] text-white rounded-md text-xs font-normal"
                    >
                      Approve
                    </button>
                  </>
                ) : null}
              </>
            ) : null}

            {user?.role === "Contractor" && val?.status === "draft" ? (
              <>
                <button
                  onClick={() => {
                    handleNeedApproval(data?.user);
                    setIsClicked(true);
                  }}
                  disabled={isCliked}
                  className="h-[44px] w-32 outline-none bg-[#5BBE0D] text-white rounded-md text-sm font-normal"
                >
                  Share with admin
                </button>
              </>
            ) : null}

            {/* <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button bg-green-500 text-white gap-x-2 w-[134px] h-[44px] border rounded-md flex justify-center items-center"
              table="table-to-xls"
              filename={`${data?.timeSheetName}`}
              sheet={`${data?.timeSheetName}`}
              buttonText="Save as .xlsx"
            /> */}
          </div>

          <RejectModal
            showModal={showModal}
            data={data}
            setShowModal={setShowModal}
          />
        </>
      )}
    </>
  );
}
