import React from "react";

import About from "../components/About/About";


function AboutPage() {
  return (
    <>
      <About/>

    </>
  );
}

export default AboutPage;
