import React from "react";

import Article from "../components/Article/Article";

function ArticlePage() {
  return (
    <>
      <Article />
    </>
  );
}

export default ArticlePage;
