import React from "react";
import cancel from "../../assests/cancelIcon.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import create from "../../assests/createTick.svg";
import { getApprovedById } from "../../Api";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { useUserContext } from "../../context";

const schema = Yup.object().shape({
  desc: Yup.string()
    .max(500, "Value cannot exceed 500")
    .required("This field is required"),
});

export default function AdminModal({ showModal, setShowModal, data }) {
  const { id } = useParams();
  const { user } = useUserContext();
  return (
    <>
      {showModal ? (
        <>
          <div className=" overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative lg:w-[538px] md:w-[400px] my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none pb-5">
                {/*header*/}
                <div className="flex items-start justify-between p-5">
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <img src={cancel} alt="cancel" />
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-6 flex-auto">
                  <div>
                    <h1 className="font-medium font-lg">
                      Add Remark For Rejecting Timesheet
                    </h1>
                  </div>
                  <div className="mt-4 text-[14px] text-[#666666] ">
                    <Formik
                      validationSchema={schema}
                      initialValues={{
                        desc: "",
                      }}
                      onSubmit={async (values) => {
                        const newDate = format(new Date(), "MM/dd/yyyy");
                        try {
                          const res = await getApprovedById(id, {
                            ...data,
                            status: "Rejected",
                            approvalDate: `${newDate}`,
                            approvedBy: `${user?.name}`,
                            ...values,
                          });
                          if (res.status) {
                            Swal.fire({
                              width: "16em",
                              height: "16em",
                              position: "center",
                              icon: "success",
                              title: `${res?.data?.data}`,
                              showConfirmButton: false,
                              timer: 1500,
                            });
                            setShowModal(false);
                          }
                        } catch (error) {
                          console.log(error?.message);
                        }
                      }}
                    >
                      {({ isSubmitting, errors, touched, setValues }) => (
                        <Form>
                          <div className="flex flex-col py-3">
                            <label htmlFor="desc" className="text-[#7A7A79]">
                              Description
                            </label>
                            <Field
                              name="desc"
                              as="textarea"
                              placeholder="Enter Description"
                              rows="8"
                              className={`border resize-none border-1 border-[#B8B7B6] rounded mt-1 pl-2 outline-none  ${
                                touched.desc && errors.desc ? "is-invalid" : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="desc"
                              className="text-red-700 font-normal font-base text-left"
                            />
                          </div>

                          <div className="flex justify-between gap-4 py-3">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="flex items-center justify-center font-normal text-sm h-[44px] w-full bg-black  mt-3 text-white rounded"
                            >
                              <img src={create} alt="create" />
                              <p className="ml-2">Save</p>
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
