import React, { useState, useEffect } from "react";
import {
  allContractors,
  categoriesList,
  allCalendarEvent,
  projectList,
  taskList,
} from "../../Api";
import Spinner from "./Spinner";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

export default function DataList({ setQuery, query }) {
  const [flyer, setFlyer] = useState(false);
  const [flyer2, setFlyer2] = useState(false);
  const [flyer3, setFlyer3] = useState(false);
  const [flyer4, setFlyer4] = useState(false);
  const [flyer5, setFlyer5] = useState(false);
  const [flyer6, setFlyer6] = useState(false);
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [contractor, setContractor] = useState();
  const [category, setCategory] = useState();
  const [project, setProject] = useState();
  const [task, setTask] = useState();
  const [showCat, setShowCat] = useState();
  const [showProject, setShowProject] = useState();
  const [showTask, setShowTask] = useState();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [submittedDate, setSubmittedDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    get_all_contractors();
    category_list();
    getEvents();
    project_list();
    task_list();
  }, []);

  const get_all_contractors = async () => {
    const res = await allContractors();
    if (res?.status === 200) {
      setData(res?.data?.contractors);
      setLoading(false);
    }
  };

  const category_list = async () => {
    const res = await categoriesList();
    if (res?.status === 200) {
      setCategory(res?.data?.data);
      setLoading(false);
    }
  };

  const project_list = async () => {
    const res = await projectList();
    if (res?.status === 200) {
      setProject(res?.data?.data);
      setLoading(false);
    }
  };

  const task_list = async () => {
    const res = await taskList();
    if (res?.status === 200) {
      setTask(res?.data?.data);
      setLoading(false);
    }
  };

  const getEvents = async () => {
    const res = await allCalendarEvent();
    if (res?.status === 200) {
      setEvent(res?.data?.allevents);
      setLoading(false);
    }
  };

  const filterCat = category?.filter((value) => value !== null);
  const filterProject = project?.filter((value) => value !== null);
  const filtertask = task?.filter((value) => value !== null);

  const handleRange = (value) => {
    const isSelected = value[0].startDate !== value[0].endDate;
    if (isSelected) {
      const start = format(new Date(value[0].startDate), "MM/dd/yyyy");
      const end = format(new Date(value[0].endDate), "MM/dd/yyyy");
      setQuery({ ...query, startDate: start, endDate: end });
    }
  };
  const handleSubmittedDateRange = (value) => {
    const isSelected = value[0].startDate !== value[0].endDate;
    if (isSelected) {
      const start = format(new Date(value[0].startDate), "MM/dd/yyyy");
      const end = format(new Date(value[0].endDate), "MM/dd/yyyy");
      setQuery({
        ...query,
        timesheetCreateAtStart: start,
        timesheetCreateAtEnd: end,
      });
    }
  };

  let matchingTitles = filterCat
    ?.map((obj1) => obj1.category)
    .filter((title1) => event?.some((obj2) => obj2.title === title1));
  let array1 = filterCat?.filter(
    (obj) => !matchingTitles.includes(obj.category)
  );

  let matchingTitles2 = filterProject
    ?.map((obj1) => obj1.project)
    .filter((title1) => event?.some((obj2) => obj2.title === title1));
  let array2 = filterProject?.filter(
    (obj) => !matchingTitles2?.includes(obj.project)
  );

  let matchingTitles3 = filtertask
    ?.map((obj1) => obj1.task)
    .filter((title1) => event?.some((obj2) => obj2.title === title1));
  let array3 = filtertask?.filter(
    (obj) => !matchingTitles3?.includes(obj.task)
  );

  return (
    <div>
      <div className="flex lg:flex-row md:flex-row flex-col gap-y-3 gap-x-5 w-full px-5">
        <div className="w-full">
          <h1 className="font-medium text-base space-y-3">Contractor</h1>
          <div className="relative w-full border">
            <button
              id="dropdownDelayButton"
              data-dropdown-toggle="dropdownDelay"
              data-dropdown-delay="500"
              data-dropdown-trigger="hover"
              className="text-black w-full justify-between outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
              type="button"
              onClick={() => {
                setFlyer(!flyer);
                setFlyer2(false);
                setFlyer3(false);
                setFlyer4(false);
                setFlyer5(false);
                setFlyer6(false);
              }}
            >
              <p>{`${contractor ? contractor : "All"}`}</p>
              <svg
                className="w-4 h-4 ml-2"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            <div
              id="dropdownDelay"
              className={` ${
                flyer ? "block" : "hidden"
              } z-40 absolute w-full border bg-white divide-y divide-gray-100 shadow dark:bg-gray-700`}
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDelayButton"
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    {data?.map((obj, i) => (
                      <li
                        key={obj?._id}
                        onClick={() => {
                          setQuery({ ...query, contactor: obj.name });
                          setContractor(obj.name);
                          setFlyer(false);
                        }}
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        {obj?.name}
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="w-full relative">
          <h1 className={` font-medium text-base space-y-3`}>Approval Date</h1>
          <div className="relative">
            <div
              className={`flex bg-white absolute items-center h-11 w-full border `}
            >
              <button
                onClick={() => {
                  setFlyer3(!flyer3);
                  setFlyer(false);
                  setFlyer2(false);
                  setFlyer4(false);
                  setFlyer5(false);
                  setFlyer6(false);
                }}
                className="text-sm w-full text-left lg:px-5 px-3 h-full text-gray-400 font-medium"
              >
                Select Date Range
              </button>
            </div>
            <div
              className={`absolute z-10 top-10 h-11 w-full border ${
                flyer3 ? "block" : "hidden"
              }`}
            >
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
                showSelectionPreview={true}
                onRangeFocusChange={() => handleRange(state)}
              />
            </div>
          </div>
        </div>

        <div className="w-full ">
          <h1 className="font-medium text-base space-y-3 md:mt-0 mt-10">
            Category
          </h1>
          <div className="relative w-full border">
            <button
              id="dropdownDelayButton"
              data-dropdown-toggle="dropdownDelay"
              data-dropdown-delay="500"
              data-dropdown-trigger="hover"
              className="text-black w-full justify-between outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
              type="button"
              onClick={() => {
                setFlyer2(!flyer2);
                setFlyer(false);
                setFlyer3(false);
                setFlyer4(false);
                setFlyer5(false);
                setFlyer6(false);
              }}
            >
              <p>{`${showCat ? showCat : "All"}`}</p>
              <svg
                className="w-4 h-4 ml-2"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            <div
              id="dropdownDelay"
              className={` ${
                flyer2 ? "block" : "hidden"
              } z-40 absolute w-full border bg-white divide-y divide-gray-100 shadow dark:bg-gray-700`}
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDelayButton"
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    {array1?.map((obj, i) => (
                      <>
                        {obj.category !== null ? (
                          <li
                            key={i}
                            onClick={() => {
                              setQuery({ ...query, category: obj.category });
                              setFlyer2(false);
                              setShowCat(obj.category);
                            }}
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            {obj.category}
                          </li>
                        ) : null}
                      </>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row md:mt-5 mt-3 md:flex-row flex-col gap-y-3 gap-x-5 w-full px-5">
        <div className="w-full">
          <h1 className="font-medium text-base space-y-3">ID</h1>
          <div
            onClick={() => {
              setFlyer(false);
              setFlyer2(false);
              setFlyer3(false);
              setFlyer4(false);
              setFlyer5(false);
              setFlyer6(false);
            }}
            className="relative w-full border"
          >
            <input
              placeholder="Enter ID"
              className="md:h-11 h-9 w-full outline-none lg:px-5 px-3"
              onChange={(e) => {
                setQuery({ ...query, ID: e.target.value });
              }}
            />
          </div>
        </div>

        <div className="w-full">
          <h1 className="font-medium text-base space-y-3">Hour</h1>
          <div
            onClick={() => {
              setFlyer(false);
              setFlyer2(false);
              setFlyer3(false);
              setFlyer4(false);
              setFlyer5(false);
              setFlyer6(false);
            }}
            className="relative w-full border"
          >
            <input
              placeholder="Enter hour"
              className="md:h-11 h-9 w-full outline-none lg:px-5 px-3"
              onChange={(e) => setQuery({ ...query, hour: e.target.value })}
            />
          </div>
        </div>

        <div className="w-full">
          <h1 className="font-medium text-base space-y-3">Rate</h1>
          <div
            onClick={() => {
              setFlyer(false);
              setFlyer2(false);
              setFlyer3(false);
              setFlyer4(false);
              setFlyer5(false);
              setFlyer6(false);
            }}
            className="relative w-full border"
          >
            <input
              placeholder="Enter rate"
              className="md:h-11 h-9 w-full outline-none lg:px-5 px-3"
              onChange={(e) => setQuery({ ...query, rate: e.target.value })}
            />
          </div>
        </div>
      </div>

      <div className="flex mt-3 lg:flex-row md:flex-row flex-col gap-y-3 gap-x-5 w-full px-5">
        <div className="w-full ">
          <h1 className="font-medium text-base space-y-3 md:mt-0 mt-10">
            Project Name
          </h1>
          <div className="relative w-full border">
            <button
              id="dropdownDelayButton"
              data-dropdown-toggle="dropdownDelay"
              data-dropdown-delay="500"
              data-dropdown-trigger="hover"
              className="text-black w-full justify-between outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
              type="button"
              onClick={() => {
                setFlyer6(!flyer6);
                setFlyer(false);
                setFlyer3(false);
                setFlyer4(false);
                setFlyer5(false);
                setFlyer2(false);
              }}
            >
              <p>{`${showProject ? showProject : "All"}`}</p>
              <svg
                className="w-4 h-4 ml-2"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            <div
              id="dropdownDelay"
              className={` ${
                flyer6 ? "block" : "hidden"
              } z-40 absolute w-full border bg-white divide-y divide-gray-100 shadow dark:bg-gray-700`}
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDelayButton"
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    {array2?.map((obj, i) => (
                      <>
                        {obj.project !== null ? (
                          <li
                            key={i}
                            onClick={() => {
                              setQuery({ ...query, project: obj.project });
                              setFlyer6(false);
                              setShowProject(obj.project);
                            }}
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            {obj.project}
                          </li>
                        ) : null}
                      </>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="w-full relative">
          <h1 className={` font-medium text-base space-y-3`}>Submitted Date</h1>
          <div className="relative">
            <div
              className={`flex bg-white absolute items-center h-11 w-full border `}
            >
              <button
                onClick={() => {
                  setFlyer5(!flyer5);
                  setFlyer(false);
                  setFlyer4(false);
                  setFlyer3(false);
                  setFlyer6(false);
                  setFlyer2(false);
                }}
                className="text-sm w-full text-left lg:px-5 px-3 h-full text-gray-400 font-medium"
              >
                Select Date Range
              </button>
            </div>
            <div
              className={`absolute z-10 top-10 h-11 w-full border ${
                flyer5 ? "block" : "hidden"
              }`}
            >
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setSubmittedDate([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={submittedDate}
                showSelectionPreview={true}
                onRangeFocusChange={() =>
                  handleSubmittedDateRange(submittedDate)
                }
              />
            </div>
          </div>
        </div>

        <div className="w-full ">
          <h1 className="font-medium text-base space-y-3 md:mt-0 mt-10">
            Task
          </h1>
          <div className="relative w-full border">
            <button
              id="dropdownDelayButton"
              data-dropdown-toggle="dropdownDelay"
              data-dropdown-delay="500"
              data-dropdown-trigger="hover"
              className="text-black w-full justify-between outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
              type="button"
              onClick={() => {
                setFlyer4(!flyer4);
                setFlyer(false);
                setFlyer3(false);
                setFlyer2(false);
                setFlyer5(false);
                setFlyer6(false);
              }}
            >
              <p>{`${showTask ? showTask : "All"}`}</p>
              <svg
                className="w-4 h-4 ml-2"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            <div
              id="dropdownDelay"
              className={` ${
                flyer4 ? "block" : "hidden"
              } z-40 absolute w-full border bg-white divide-y divide-gray-100 shadow dark:bg-gray-700`}
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDelayButton"
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    {array3?.map((obj, i) => (
                      <>
                        {obj.task !== null ? (
                          <li
                            key={i}
                            onClick={() => {
                              setQuery({ ...query, task: obj.task });
                              setFlyer4(false);
                              setShowTask(obj.task);
                            }}
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            {obj.task}
                          </li>
                        ) : null}
                      </>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
