import React from 'react'

import ContactUs from "../components/ContactUs/ContactUs"

function ContactUsPage() {
  return (
    <>

<ContactUs></ContactUs>

    </>
    
  )
}

export default ContactUsPage