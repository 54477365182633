import React, { useState, useEffect } from "react";
import search from "../../assests/searchIcon.svg";
import plus from "../../assests/plusIcon.svg";
import edit from "../../assests/editIcon.svg";
import AdminModal from "./AdminModal";
import { TrashIcon } from "../iconComponent/Icon";
import { allAdmins } from "../../Api";
import EditAdminModal from "./EditAdminModal";
import DeleteModal from "./DeleteModal";
import { useUserContext } from "../../context";

export default function Admin() {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [query, setQuery] = useState();
  const [id, setId] = useState();
  const [deleteId, setDeleteId] = useState();
  const [data, setData] = useState();

  const { user } = useUserContext();

  useEffect(() => {
    getAdmins();
    // eslint-disable-next-line
  }, [data, query]);
  const getAdmins = async () => {
    const res = await allAdmins(query);
    if (res?.data?.success) {
      setData(res?.data?.admin);
    }
  };
  return (
    <>
      <div className="w-full md:px-5">
        <div className="flex items-center lg:justify-between lg:flex-row md:justify-between md:flex-row  flex-col justify-center my-2">
          <div className="w-7/12 px-4">
            <h1 className="font-semibold text-lg lg:text-left md:text-left text-center">
              All Admins
            </h1>
          </div>
          <div className="w-[233px] md:h-[52px] h-10 border border-[#A1A1A1] flex items-center rounded-lg lg:mt-0 mt-5">
            <img src={search} alt="search" className="w-5 h-5 ml-3" />
            <input
              placeholder="Search User by name or email"
              onChange={(e) => setQuery(e.target.value)}
              className="placeholder-[#A1A1A1] text-sm w-full pl-2 outline-none text-[#A1A1A1]"
            />
          </div>
        </div>
        <div className=" flex items-center lg:flex-row lg:justify-between md:flex-row md:justify-between justify-center flex-col  my-7 px-4">
          <h1 className="font-semibold text-lg">
            Total Admins <span>{data ? data.length : "0"}</span>
          </h1>

          {user?.role === "Super Admin" ? (
            <button
              className="w-auto px-2 h-[44px] bg-black rounded-lg flex items-center justify-center lg:mt-0 mt-3"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img src={plus} alt="create" />
              <p className="text-white text-xs font-normal pl-1">
                Create an Admin Account
              </p>
            </button>
          ) : null}
        </div>
        <div className="h-screen overflow-y-auto px-4">
          {data?.map((obj, i) => (
            <div key={obj?._id} className="py-3 ">
              <hr className="mb-6" />
              <div className="lg:flex md:flex items-center justify-between">
                <div className="flex">
                  <div>
                    <p className="text-base font-normal text-gray-500">Name</p>
                    <p className="text-base font-normal text-gray-500">Email</p>

                    <p className="text-base font-normal text-gray-500">
                      Contact
                    </p>
                    <p className="text-base font-normal text-gray-500">
                      Date of Birth
                    </p>
                    <p className="text-base font-normal text-gray-500">Role</p>
                  </div>
                  <div className="ml-5">
                    <p className=" text-base font-medium">{obj.name}</p>
                    <p className=" text-base font-medium">{obj.email}</p>
                    {obj.phoneNumber ? (
                      <p className=" text-base font-medium">
                        +{obj.phoneNumber}
                      </p>
                    ) : (
                      <p className=" text-base text-gray-500 font-normal">
                        'number required'
                      </p>
                    )}

                    {obj.DOB ? (
                      <p className=" text-base font-medium">{obj.DOB}</p>
                    ) : (
                      <p className=" text-base text-gray-500 font-normal">
                        'date-of-birth required'
                      </p>
                    )}
                    <p className=" text-base text-[#0033EA] font-semibold">
                      {obj.role}
                    </p>
                  </div>
                </div>
                {user?.role === "Super Admin" ? (
                  <div className="md:space-y-3 space-y-0 md:mt-0 mt-3 gap-x-3 md:block flex justify-center items-center">
                    <div
                      onClick={() => {
                        setEditModal(true);
                        setId(obj._id);
                      }}
                      className="cursor-pointer"
                    >
                      <img src={edit} alt="edit" />
                    </div>
                    <div
                      onClick={() => {
                        setDeleteModal(true);
                        setDeleteId(obj._id);
                      }}
                      className="cursor-pointer"
                    >
                      <TrashIcon />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
          <AdminModal setShowModal={setShowModal} showModal={showModal} />
          <EditAdminModal
            setEditModal={setEditModal}
            editModal={editModal}
            id={id}
          />
          <DeleteModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            deleteId={deleteId}
          />
        </div>
      </div>
    </>
  );
}
