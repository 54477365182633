import React, { useState, useEffect } from "react";
import { allHolidays } from "../../Api";
import {
  format,
  formatISO,
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  parseISO,
} from "date-fns";

export default function Holidays() {
  const [value, setValue] = useState("month");
  const [event, setEvent] = useState([]);
  useEffect(() => {
    getEvents();
    // eslint-disable-next-line
  }, [event]);

  const getEvents = async () => {
    const res = await allHolidays();
    setEvent(res?.data?.allevents);
  };
  const currentMonth = new Date().getMonth();

  const getMonthData = (value) => {
    return parseInt(value?.start?.split("-")[1], 10) === currentMonth + 1;
  };
  const filteredMonth = event?.filter((obj, _) => getMonthData(obj));

  const getHolidayInWeek = filteredMonth?.filter((value) => {
    const date = value?.start?.split("T")[0];
    const weekStartDate = formatISO(new Date(), "dd-MM-yyyy");
    const weekStart = formatISO(startOfWeek(parseISO(weekStartDate))).split(
      "T"
    )[0];
    const weekEnd = formatISO(endOfWeek(parseISO(weekStartDate))).split("T")[0];

    const newValue = isWithinInterval(parseISO(date), {
      start: parseISO(weekStart),
      end: parseISO(weekEnd),
    });

    if (newValue) return value;
    return 0;
  });

  return (
    <div className="xl:w-3/5 md:2/4 w-full bg-white rounded-lg pt-2  shadow-xl mt-10 flex items-center  flex-col">
      <div className="w-full px-4">
        <div className="w-full">
          <h1 className="font-semibold text-base ">Holidays</h1>
        </div>
        <div className="w-full flex mt-4">
          <button
            onClick={() => setValue("month")}
            className={`w-32 h-8 ${
              value === "month" ? "bg-[#01464F] text-white" : ""
            }   rounded-sm`}
          >
            This Month
          </button>

          <button
            onClick={() => setValue("week")}
            className={`w-32 ml-4 h-8 ${
              value === "week" ? "bg-[#01464F] text-white" : ""
            }   rounded-sm`}
          >
            This Week
          </button>
        </div>
        <div className="py-5 h-[40vh] overflow-y-scroll">
          {value === "month" ? (
            <div>
              {filteredMonth.length > 0 ? (
                filteredMonth?.map((obj, i) => (
                  <div
                    key={obj?._id}
                    className=" w-full h-24 my-3 rounded-lg pt-5 px-5  bg-[#E5FCFF]"
                  >
                    <h1 className="text-sm font-medium">
                      {obj?.title} ({format(new Date(obj.start), "MM/dd/yyyy")}
                      to {format(new Date(obj.end), "MM/dd/yyyy")})
                    </h1>
                    <p className="font-normal text-sm">{obj?.desc}</p>
                  </div>
                ))
              ) : (
                <div className=" w-full flex justify-center items-center mt-5">
                  <p className="font-normal text-base">No Holiday this month</p>
                </div>
              )}
            </div>
          ) : (
            <div>
              {getHolidayInWeek?.length > 0 ? (
                getHolidayInWeek?.map((obj, i) => (
                  <div
                    key={obj?._id}
                    className=" w-full h-24 my-3 rounded-lg pt-5 px-5  bg-[#E5FCFF]"
                  >
                    <h1 className="text-sm font-medium">
                      {obj?.title} ({format(new Date(obj.start), "MM/dd/yyyy")}{" "}
                      to {format(new Date(obj.end), "MM/dd/yyyy")} )
                    </h1>
                    <p className="font-normal text-sm">{obj?.desc}</p>
                  </div>
                ))
              ) : (
                <div className=" w-full flex justify-center items-center mt-5">
                  <p className="font-normal text-base">No Holiday this week</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
