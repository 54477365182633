import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import angle from "../../assests/angleIcon.svg";
import { AddIcon } from "../iconComponent/Icon";
import { useUserContext } from "../../context";

export default function TimeSheet() {
  const { user } = useUserContext();

  return (
    <>
      <div className="lg:w-full md:w-[750px] w-full">
        <div className=" flex items-start ">
          {user?.role === "Admin" ? (
            <Link to={`/timesheets-reports/${user?.userId}`}>
              <div className="w-[44px] self-start h-[44px] hover:bg-[#F0FAFC] flex items-center justify-center rounded lg:mt-0">
                <img src={angle} alt="back" />
              </div>
            </Link>
          ) : (
            <Link to={`/timesheets/draft/${user?.userId}`}>
              <div className="w-[44px] self-start h-[44px] hover:bg-[#F0FAFC] flex items-center justify-center rounded lg:mt-0">
                <img src={angle} alt="back" />
              </div>
            </Link>
          )}

          <div className="w-full flex justify-between items-center h-[44px]">
            <h1 className="font-semibold text-lg lg:text-left pl-3 md:text-left text-center">
              Timesheet
            </h1>
          </div>
        </div>
        <div className="flex w-[94%] xl:flex-row mx-auto flex-col gap-y-4 justify-between items-center">
          <div className="pt-4 flex md:gap-y-0 gap-y-5 md:flex-row flex-col md:gap-x-14 gap-x-5 ">
            {user?.role === "Contractor" ? (
              <>
                <NavLink to={`/timesheets/draft/${user?.userId}`}>
                  {({ isActive }) => (
                    <button
                      className={`flex gap-x-1 ${
                        isActive ? "text-[#169AA3]" : "text-black"
                      }  justify-center items-center`}
                    >
                      <p
                        className={`border-b-2 pb-2  ${
                          isActive ? "border-[#169AA3]" : ""
                        }`}
                      >
                        Draft
                      </p>
                    </button>
                  )}
                </NavLink>
                <NavLink to={`/timesheets/approval/${user?.userId}`}>
                  {({ isActive }) => (
                    <button
                      className={`flex gap-x-1 ${
                        isActive ? "text-[#169AA3]" : "text-black"
                      }  justify-center items-center`}
                    >
                      <p
                        className={`border-b-2 pb-2  ${
                          isActive ? "border-[#169AA3]" : ""
                        }`}
                      >
                        Waiting for Approval
                      </p>
                    </button>
                  )}
                </NavLink>
              </>
            ) : (
              <>
                <NavLink to={`/timesheets/draft/${user?.userId}`}>
                  {({ isActive }) => (
                    <button
                      className={`flex gap-x-1 ${
                        isActive ? "text-[#169AA3]" : "text-black"
                      }  justify-center items-center`}
                    >
                      <p
                        className={`border-b-2 pb-2  ${
                          isActive ? "border-[#169AA3]" : ""
                        }`}
                      >
                        Draft
                      </p>
                    </button>
                  )}
                </NavLink>
                <NavLink to={`/timesheets/approval/${user?.userId}`}>
                  {({ isActive }) => (
                    <button
                      className={`flex gap-x-1 ${
                        isActive ? "text-[#169AA3]" : "text-black"
                      }  justify-center items-center`}
                    >
                      <p
                        className={`border-b-2 pb-2  ${
                          isActive ? "border-[#169AA3]" : ""
                        }`}
                      >
                        Waiting for Approval
                      </p>
                    </button>
                  )}
                </NavLink>
              </>
            )}

            <NavLink to={`/timesheets/approved/${user?.userId}`}>
              {({ isActive }) => (
                <button
                  className={`flex gap-x-1 ${
                    isActive ? "text-[#169AA3]" : "text-black"
                  }  justify-center items-center`}
                >
                  <p
                    className={`border-b-2 pb-2  ${
                      isActive ? "border-[#169AA3]" : ""
                    }`}
                  >
                    Approved
                  </p>
                </button>
              )}
            </NavLink>

            <NavLink to={`/timesheets/rejected/${user?.userId}`}>
              {({ isActive }) => (
                <button
                  className={`flex gap-x-1 ${
                    isActive ? "text-[#169AA3]" : "text-black"
                  }  justify-center items-center`}
                >
                  <p
                    className={`border-b-2 pb-2  ${
                      isActive ? "border-[#169AA3]" : ""
                    }`}
                  >
                    Rejected
                  </p>
                </button>
              )}
            </NavLink>
          </div>

          <Link to="/view-time-sheets">
            <button className="flex outline-none lg:mt-0 md:mt-0 mt-4 h-[44px] w-24 justify-center rounded-lg text-[17px] font-medium items-center gap-x-2 bg-black text-white">
              <AddIcon />
              <p>Create</p>
            </button>
          </Link>
        </div>

        <div className="w-full  px-4 mt-10">
          <Outlet />
        </div>
      </div>
    </>
  );
}
