import React, { useState, useEffect } from "react";
import { CreateIcon, AddIcon } from "../iconComponent/Icon";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import angle from "../../assests/angleIcon.svg";
import {
  getDataById,
  editDataById,
  allCalendarEvent,
  getApprovedById,
  deleteById,
} from "../../Api";
import Swal from "sweetalert2";
import { useUserContext } from "../../context";
import * as Yup from "yup";
import { format } from "date-fns";

const tableSchema = Yup.object().shape({
  miscellaneous: Yup.array().of(
    Yup.object().shape({
      cost: Yup.string().matches(/^\d+$/, "cost must be digits").nullable(),
    })
  ),
});

export default function EditViewProjectTable() {
  const [data, setData] = useState();
  const { user } = useUserContext();
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getById();
    getEvents();
    // eslint-disable-next-line
  }, []);
  const getEvents = async () => {
    const res = await allCalendarEvent();
    setEvents(res?.data?.allevents);
  };
  const getById = async () => {
    try {
      const res = await getDataById(id);
      if (res?.status) {
        setData(res?.data?.data);
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  const generateDateList = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dates = [];

    while (startDate <= endDate) {
      dates.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }

    return dates;
  };

  const expandedEvents = [];

  events?.forEach((event) => {
    const dates = generateDateList(event.start, event.end);
    dates.forEach((date) => {
      const formattedDate = date.toISOString().split("T")[0]; // Convert the Date object back to "YYYY-MM-DD" format
      const newEvent = {
        ...event,
        start: format(new Date(formattedDate), "MM/dd/yyyy"),
        end: formattedDate,
      };
      expandedEvents.push(newEvent);
    });
  });

  const containsNA = (obj) => {
    return Object.values(obj).some((value) => value === "N/A");
  };
  const getHighlightedArray = () => {
    return data?.dataSheet?.map((obj1) => {
      if (
        expandedEvents.some((obj2) => obj1.changeDate.trim() === obj2.start) ||
        containsNA(obj1)
      ) {
        return { ...obj1, color: "bg-red-600" }; // Replace 'desired-color' with your color
      }
      return obj1;
    });
  };

  const handleNeedApproval = async (values) => {
    try {
      const res = await getApprovedById(id, {
        ...values,
        status: "Need approval",
      });

      if (res) {
        navigate(`/home`);
        Swal.fire({
          width: "16em",
          height: "16em",
          position: "center",
          icon: "success",
          title: `${res?.data?.data}`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleDelete = async () => {
    const res = await deleteById(id);
    if (res) {
      navigate(`/timesheets/approval/${user?.userId}`);
      Swal.fire({
        width: "20em",
        height: "20em",
        position: "center",
        icon: "success",
        title: "Deleted successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        width: "20em",
        height: "20em",
        position: "center",
        icon: "success",
        title: "Something wents wrong?",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <>
      <Formik
        validationSchema={tableSchema}
        initialValues={{
          ...data,
          dataSheet: getHighlightedArray(),
        }}
        onSubmit={async (values) => {
          try {
            const res = await editDataById(id, values);
            if (res?.data?.success) {
              Swal.fire({
                width: "20em",
                height: "20em",
                position: "center",
                icon: "success",
                title: `${res?.data?.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
              navigate(`/timesheets/approval/${user?.userId}`);
            }
          } catch (error) {
            Swal.fire({
              width: "20em",
              height: "20em",
              title: `${error?.data?.message}`,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            });
          }
        }}
        enableReinitialize
      >
        {({ isSubmitting, errors, touched, values }) => (
          <Form className="w-full  px-3">
            <Link to={`/timesheets/draft/${user?.userId}`}>
              <div className="w-[44px] self-start h-[44px] hover:bg-[#F0FAFC] flex items-center justify-center rounded lg:mt-0">
                <img src={angle} alt="back" />
              </div>
            </Link>
            <div className="flex gap-x-3 lg:flex-row flex-col md:flex-col justify-center items-center">
              <div className="flex w-2/4 flex-col py-2">
                <label
                  htmlFor="timeSheetName"
                  className="text-sm text-[#7A7A79]"
                >
                  Timesheet Name
                </label>
                <Field
                  name="timeSheetName"
                  // value={addName ? addName + user?.name : null}
                  // placeholder="Enter timesheet name"
                  className={`border border-1 border-[#B8B7B6] mt-1 h-[35px] pl-2 outline-none ${
                    touched.timeSheetName && errors.timeSheetName
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </div>
              <div className="flex w-2/4 flex-col py-2">
                <label htmlFor="currentDate" className="text-sm text-[#7A7A79]">
                  Timesheet Month
                </label>
                <Field
                  name="currentDate"
                  disabled={true}
                  type="month"
                  // placeholder="Enter Current Date "
                  className={`border-b border-1 border-[#B8B7B6] mt-1 h-[35px] pl-2 outline-none  ${
                    touched.currentDate && errors.currentDate
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </div>
            </div>

            <div>
              <div>
                <hr className="w-full pb-5" />
                <p className="font-medium text-sm text-[#075A66] py-2">
                  Time sheet name
                </p>
                <div className="flex flex-col w-full">
                  <div className="w-full">
                    <div className="relative block w-full">
                      <FieldArray name="dataSheet">
                        {({ insert, remove, push }) => (
                          <div className=" rounded-lg w-full fixTableHead h-screen ">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="border-b bg-[#E3E8EF] font-medium rounded-lg dark:border-neutral-500">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                                  >
                                    Changes Date
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                  >
                                    ID
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                  >
                                    INVOICE CATEGORIES
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                  >
                                    PROJECT &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                  >
                                    TASK
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap font-semibold text-sm text-[#104083]"
                                  >
                                    HOUR
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-4 whitespace-nowrap  font-semibold text-sm text-[#104083]"
                                  >
                                    COMMENTS (include risks, issues, details as
                                    applicable)
                                  </th>
                                </tr>
                              </thead>
                              {values?.dataSheet?.length > 0 &&
                                values?.dataSheet?.map((dataSheet, index) => (
                                  <tbody key={index} className="w-full">
                                    <>
                                      <tr className="border-b dark:border-neutral-500">
                                        <td
                                          width="20%"
                                          className="whitespace-nowrap text-center "
                                        >
                                          <Field
                                            name={`dataSheet.${index}.changeDate`}
                                            disabled={true}
                                            value={
                                              values?.dataSheet?.[index]
                                                ?.changeDate
                                            }
                                            className={`h-full border-none outline-none w-full px-6 py-4 ${
                                              dataSheet?.color
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }`}
                                            placeholder="12-06-2021"
                                          />
                                        </td>
                                        <td className="whitespace-nowrap text-center ">
                                          <Field
                                            name={`dataSheet.${index}.ID`}
                                            className={`h-full border-none outline-none w-full px-6 py-4 ${
                                              dataSheet?.color
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }`}
                                            // placeholder="126106"
                                          />
                                        </td>
                                        <td className="whitespace-nowrap text-center ">
                                          <Field
                                            name={`dataSheet.${index}.invoiceCategory`}
                                            className={`h-full border-none outline-none w-full px-6 py-4 ${
                                              dataSheet?.color
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }`}
                                            // placeholder="DFAS"
                                          />
                                        </td>
                                        <td className="whitespace-nowrap text-center ">
                                          <Field
                                            name={`dataSheet.${index}.project`}
                                            className={`h-full border-none outline-none w-full px-6 py-4 ${
                                              dataSheet?.color
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }`}
                                            // placeholder="Data Foundation"
                                          />
                                        </td>
                                        <td className="whitespace-nowrap text-center ">
                                          <Field
                                            name={`dataSheet.${index}.task`}
                                            className={`h-full border-none outline-none w-full px-6 py-4 ${
                                              dataSheet?.color
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }`}
                                            // placeholder="Added a new View"
                                          />
                                        </td>
                                        <td className="whitespace-nowrap text-center ">
                                          <Field
                                            name={`dataSheet.${index}.hour`}
                                            type="number"
                                            min="0"
                                            className={`h-full border-none outline-none w-full px-6 py-4 ${
                                              dataSheet?.color
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }`}
                                            // placeholder="0"
                                          />
                                        </td>
                                        <td className="whitespace-nowrap text-center">
                                          <Field
                                            name={`dataSheet.${index}.comments`}
                                            className={`h-full border-none outline-none w-full px-6 py-4 ${
                                              dataSheet?.color
                                                ? "bg-green-500 text-white"
                                                : ""
                                            }`}
                                            // placeholder="Include October hours"
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  </tbody>
                                ))}
                            </table>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-5">
                <h1 className="text-base font-semibold">
                  Miscellaneous expenses
                </h1>
                <FieldArray name="miscellaneous">
                  {({ insert, remove, push }) => (
                    <div className="">
                      <div className="lg:flex md:flex-row md:flex hidden w-full flex-col lg:gap-x-44 md:gap-x-52 gap-y-5">
                        <label className="text-base font-semibold">$Cost</label>
                        <label className="text-base font-semibold">
                          Reason
                        </label>
                        <label className="text-base font-semibold lg:-ml-4 md:-ml-5">
                          Date
                        </label>
                      </div>
                      {values?.miscellaneous?.length > 0 &&
                        values?.miscellaneous?.map((obj, i) => (
                          <div
                            key={i}
                            className="flex md:flex-row flex-col gap-x-4 gap-y-5"
                          >
                            <div className="w-full">
                              <label className="text-base md:hidden block font-semibold">
                                $Cost
                              </label>

                              <Field
                                placeholder="Enter cost"
                                name={`miscellaneous.${i}.cost`}
                                className={`border-gray-500 border rounded-lg mt-1 outline-none w-full pl-2 py-2`}
                              />
                              <ErrorMessage
                                component="div"
                                name={`miscellaneous.${i}.cost`}
                                className="text-red-700 font-normal font-base text-left"
                              />
                            </div>
                            <div className="w-full">
                              <label className="text-base md:hidden block font-semibold">
                                Reason
                              </label>
                              <Field
                                placeholder="Reason"
                                name={`miscellaneous.${i}.reason`}
                                className={`border-gray-500 border rounded-lg mt-1 outline-none w-full pl-2 py-2`}
                              />
                            </div>
                            <div className="w-full">
                              <label className="text-base md:hidden block font-semibold lg:-ml-4 md:-ml-5">
                                Date
                              </label>
                              <Field
                                name={`miscellaneous.${i}.date`}
                                type="date"
                                className={`border rounded-lg outline-none mt-1 w-full pl-2`}
                              />
                            </div>
                          </div>
                        ))}
                      <div className=" flex justify-center pb-6 items-center mt-7">
                        <button
                          type="button"
                          onClick={() => {
                            push({
                              cost: "",
                              reason: "",
                              date: "",
                            });
                          }}
                          className={`w-[166px] h-[36px]  flex justify-center items-center gap-x-2 border border-dotted border-black rounded-[4px]`}
                        >
                          <AddIcon />
                          <p>Add Miscellaneous</p>
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>

              <div className="pb-7 flex flex-col gap-x-3 mt-14 lg:flex lg:flex-row md:flex-row  md:flex lg:justify-end md:justify-end justify-center items-center">
                {data?.status === "draft" ? (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        handleDelete();
                      }}
                      className="h-[44px] w-32 outline-none bg-red-500 text-white rounded-md text-sm font-normal"
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        handleNeedApproval(values);
                      }}
                      className="h-[44px] w-32 outline-none bg-[#5BBE0D] text-white rounded-md text-sm font-normal"
                    >
                      Share with admin
                    </button>
                  </>
                ) : null}

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="font-normal h-11 w-32 bg-black text-white rounded flex items-center gap-x-1 justify-center"
                >
                  <CreateIcon />
                  <p>Save</p>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
