import React from "react";
import Service from "../components/ServicePageComponent/Service";

function ServicePage() {
  return (
    <>
      <Service></Service>
    </>
  );
}

export default ServicePage;
