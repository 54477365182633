import React from "react";
import * as Yup from "yup";
import cancel from "../../assests/cancelIcon.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import { updatePasswordById } from "../../Api";

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(4, "Password must be 4 characters at minimum")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .label("Confirm Password")
    .required()
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

export default function EditPassword({ showModal, setShowModal, id }) {
  return (
    <>
      {showModal ? (
        <>
          <div className=" overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative lg:w-[538px] md:w-[400px] my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none pb-5">
                {/*header*/}
                <div className="flex items-start justify-between p-5">
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <img src={cancel} alt="cancel" />
                  </button>
                </div>
                {/*body*/}
                <div className="w-full flex justify-center items-center">
                  <div className=" bg-white rounded-2xl w-full flex justify-center px-9">
                    <div className="w-full">
                      <div className="mt-4 text-[14px] text-[#666666] ">
                        <Formik
                          initialValues={{
                            password: "",
                            confirmPassword: "",
                          }}
                          validationSchema={loginSchema}
                          onSubmit={async (values) => {
                            if (values.confirmPassword) {
                              delete values.confirmPassword;
                            }
                            try {
                              const res = await updatePasswordById(id, values);
                              if (res?.status === 200) {
                                setShowModal(false);
                                Swal.fire({
                                  width: "20em",
                                  height: "20em",
                                  position: "center",
                                  icon: "success",
                                  title: `${res?.data?.message}`,
                                  showConfirmButton: false,
                                  timer: 1500,
                                });
                              }
                            } catch (error) {
                              Swal.fire({
                                width: "20em",
                                height: "20em",
                                title: `${error?.data?.message}`,
                                showClass: {
                                  popup:
                                    "animate__animated animate__fadeInDown",
                                },
                                hideClass: {
                                  popup: "animate__animated animate__fadeOutUp",
                                },
                              });
                            }
                          }}
                        >
                          {({ isSubmitting, errors, touched }) => (
                            <Form>
                              <div className="flex flex-col mt-3">
                                <label htmlFor="password">New Password</label>
                                <Field
                                  type="password"
                                  name="password"
                                  placeholder="Password"
                                  className={` border-2 border-[#818181] rounded mt-1 h-10 pl-2 outline-none  ${
                                    touched.password && errors.password
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="password"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>
                              <div className="flex flex-col mt-3">
                                <label htmlFor="confirmPassword">
                                  Confirm Password
                                </label>
                                <Field
                                  type="password"
                                  name="confirmPassword"
                                  placeholder="Confirm Password"
                                  className={` border-2 border-[#818181] rounded mt-1 h-10 pl-2 outline-none  ${
                                    touched.confirmPassword &&
                                    errors.confirmPassword
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="confirmPassword"
                                  className="text-red-700 font-normal font-base text-left"
                                />
                              </div>
                              <div className="flex justify-between gap-4 py-3">
                                <button
                                  type="button"
                                  className="font-normal text-sm w-full h-[44px] mt-3 border border-gray-500 rounded"
                                  onClick={() => {
                                    setShowModal(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  disabled={isSubmitting}
                                  className="flex items-center justify-center font-normal text-sm h-[44px] w-full bg-black  mt-3 text-white rounded"
                                >
                                  <p className="ml-2">Update Password</p>
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
