import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assests/logo.svg";
// import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useUserContext } from "../../../context";

const NavBar = ({ darkMode }) => {
  const [open, setOpen] = React.useState(false);
  // const [flyer, setFlyer] = React.useState(false);

  const { user, setUser } = useUserContext();
  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);
  const getUser = () => {
    const data = localStorage.getItem("user");
    if (data) {
      setUser(JSON.parse(data));
    } else {
      setUser(null);
    }
  };

  return (
    <>
      <div
        className={`${
          user ? "hidden" : ""
        } sticky top-0 w-[100%]  2xl:h-[90px] xl:h-[80px] lg:h-[80px] md:h-[60px] h-[62px] 3xl:px-[90px]  2xl:px-[70px] xl:px-[60px] lg:px-[60px] md:px-[45px] lg:pt-[32px] md:pt-[16px] lg:pb-[13px]  md:pb-[2px] px-5 pt-6 pb-7 z-50  ${
          darkMode ? "bg-[#595959]" : "bg-[#0E292D]"
        }  `}
      >
        <div className=" mx-auto h-full ">
          <div className="flex h-full justify-between justify-items-center ">
            <div className="flex justify-start items-center lg:w-1/2 md:w-[200px]  lg:flex-1   ">
              <a href="https://guddge.com/">
                <img
                  className="xl:h-12 lg:h-10 md:h-8 h-9 w-auto "
                  src={Logo}
                  alt="guddge-logo"
                />
              </a>
            </div>
            <div className="-mr-2 -my-3 xl:hidden lg:hidden md:hidden block">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 dark:text-black hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setOpen(!open)}
              >
                <span className="sr-only">Open menu</span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M21 6.5H3"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 12.5H3"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 18.5H3"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>

            <nav className="hidden md:flex xl:gap-12 lg:gap-10 md:gap-6 h-full  lg:w-[70%] md:w-[50%] justify-end items-center ">
              {/* <a
                href="https://guddgeweb.azurewebsites.net/"
                className="xl:text-base md:text-sm font-normal font-inter dark:text-white  text-white "
              >
                Home
              </a> */}

              {/* <a
                href="https://guddgeweb.azurewebsites.net/?page_id=173"
                className="xl:text-base md:text-sm font-normal font-inter font-medium text-white hover:text-white"
              >
                About
              </a> */}

              {/* <a
                href="https://guddgeweb.azurewebsites.net/?page_id=243"
                className="xl:text-base md:text-sm font-normal font-inter text-white hover:text-white"
              >
                Services
              </a> */}
              {/* <Link
                to="/client"
                className="xl:text-base md:text-sm font-normal font-inter text-white hover:text-white"
              >
                Client
              </Link> */}
              {/* <div className="relative">
                <button
                  type="button"
                  className="
                   group  rounded-md text-white inline-flex items-center xl:text-base md:text-sm font-normal font-inter hover:text-white  pb-8'
                  "
                  onClick={() => {
                    setFlyer(!flyer);
                    setFlyerTwo(false);
                  }}
                >
                  <span>Showcase</span>

                  <svg
                    className={
                      flyer === true
                        ? "transform rotate-180 ml-2 h-5 w-5 text-white group-hover:white transition ease-out duration-200"
                        : "transform rotate-0 transition ease-out duration-200 ml-2 h-5 w-5 text-white group-hover:text-white"
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                <div
                  onMouseLeave={() => setFlyer(false)}
                  className={
                    flyer
                      ? " opacity-100 translate-y-0 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-60 max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : " opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-full max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 hidden"
                  }
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden ">
                    <div className="relative grid gap-6 bg-white px-5 py-5 sm:gap-8 sm:p-8 dark:bg-[#04505B] text-black dark:text-white">
                      <a
                        href="https://guddgeweb.azurewebsites.net/?page_id=230"
                        className="-m-3 p-3 flex items-start rounded-lg "
                      >
                        <div className="ml-4 xl:text-base md:text-sm font-normal font-inter">
                          <h1>Portfolio</h1>
                        </div>
                      </a>
                      <a
                        href="https://guddgeweb.azurewebsites.net/?page_id=329"
                        className="-m-3 p-3 flex items-start rounded-lg "
                      >
                        <div className="ml-4 xl:text-base md:text-sm font-normal font-inter">
                          <h1>Articles</h1>
                        </div>
                      </a>

                      <Link
                        to="/team-members"
                        className="-m-3 p-3 flex items-start rounded-lg "
                      >
                        <div className="ml-4 xl:text-base md:text-sm font-normal font-inter">
                          <h1>Team Members</h1>
                        </div>
                      </Link>

                      <Link
                        to="/career"
                        className="-m-3 p-3 flex items-start rounded-lg "
                      >
                        <div className="ml-4 xl:text-base md:text-sm font-normal font-inter">
                          <h1>Career</h1>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <a
                href="https://guddgeweb.azurewebsites.net/?page_id=111"
                className="xl:text-base md:text-sm font-normal font-inter text-white hover:text-white"
              >
                Contact
              </a> */}

              {user ? (
                <Link
                  to=""
                  className="xl:text-base md:text-sm font-normal font-inter text-white hover:text-gray-900"
                >
                  Dashboard
                </Link>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="xl:text-base md:text-sm font-normal font-inter text-white hover:text-gray-900"
                  >
                    Login
                  </Link>
                  <Link
                    to="/signup"
                    className="xl:text-base md:text-sm font-normal font-inter text-white hover:text-gray-900"
                  >
                    Signup
                  </Link>
                </>
              )}
              {/* 
              <div className="  w-6 h-6 flex items-center  text-black justify-center ">
                <DarkModeSwitch
                  checked={darkMode}
                  onChange={toggleDarkMode}
                  size={20}
                  className={`text-white dark:text-white`}
                />
              </div> */}
            </nav>
          </div>
        </div>

        <div
          className={
            open
              ? "opacity-100 scale-100   ease-out duration-200 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              : "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden hidden"
          }
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-[#078CA0] divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5 ">
              <div className="flex items-center justify-between ">
                <div>
                  <img className="h-8 w-auto" src={Logo} alt="Workflow" />
                </div>
                <div className="-mr-2 ">
                  <button
                    type="button"
                    className="bg-white dark:bg-[#003036] rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100  "
                    onClick={() => setOpen(!open)}
                  >
                    <span className="sr-only">Close menu</span>

                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8 ">
                  {/* <a
                    href="https://guddgeweb.azurewebsites.net/"
                    className="xl:text-base md:text-sm font-normal font-inter dark:text-black  text-black "
                  >
                    Home
                  </a>
                  <a
                    href="https://guddgeweb.azurewebsites.net/?page_id=173"
                    className="xl:text-base md:text-sm font-normal font-inter text-black"
                  >
                    About
                  </a>
                  <a
                    href="https://guddgeweb.azurewebsites.net/?page_id=243"
                    className="xl:text-base md:text-sm font-normal font-inter text-black"
                  >
                    Services
                  </a> */}
                  {/* <Link to="/client">
                    <span className="xl:text-base md:text-sm font-normal font-inter text-black">
                      Clients
                    </span>
                  </Link> */}

                  {/* <div className="relative">
                    <button
                      type="button"
                      className="
              group  rounded-md text-black inline-flex items-center text-base hover:text-gray-900 pb-8'
             "
                      onClick={() => {
                        setFlyer(!flyer);
                        setFlyerTwo(false);
                      }}
                    >
                      <svg
                        className="flex-shrink-0 h-6 w-6  dark:text-gray-200 "
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                        />
                      </svg>
                      <span className="xl:text-base md:text-sm font-normal font-inter text-black">
                        Showcase
                      </span>

                      <svg
                        className={
                          flyer === true
                            ? "transform rotate-180 ml-2 h-5 w-5 text-gray-200 stroke-black dark:stroke-gray-200   group-hover:text-gray-500 transition ease-out duration-200 "
                            : "transform rotate-0 transition ease-out duration-200 ml-2 h-5 w-5  stroke-black dark:stroke-gray-200 text-gray-200 group-hover:text-gray-500 "
                        }
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>

                    <div
                      onMouseLeave={() => setFlyer(false)}
                      className={
                        flyer
                          ? "  opacity-100 translate-y-0 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-80 max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 "
                          : " opacity-0 translate-y-1 absolute z-0 -ml-4 mt-3 transform px-2 w-full max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 hidden"
                      }
                    >
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden dark:bg-[#04505B]">
                        <div className="relative grid gap-4 bg-white px-5 py-6 dark:bg-[#04505B] ">
                          <Link
                            to="/portfolio"
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-[#078CA0]"
                          >
                            <div className="ml-4  dark:text-gray-200 ">
                              <h1>Portfolio</h1>
                            </div>
                          </Link>
                          <Link
                            to="/article"
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-[#078CA0]"
                          >
                            <div className="ml-4  dark:text-gray-200">
                              <h1>Articles</h1>
                            </div>
                          </Link>
                          <Link
                            to="/team-members"
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-[#078CA0]"
                          >
                            <div className="ml-4  dark:text-gray-200">
                              <h1>Team Members</h1>
                            </div>
                          </Link>
                          <Link
                            to="/career"
                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-[#078CA0]"
                          >
                            <div className="ml-4  dark:text-gray-200">
                              <h1>Career</h1>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <a
                    href="https://guddgeweb.azurewebsites.net/?page_id=111"
                    className="xl:text-base md:text-sm font-normal font-inter text-black"
                  >
                    Contact
                  </a> */}

                  {user ? (
                    <Link
                      to=""
                      className="xl:text-base md:text-sm font-normal font-inter text-black hover:text-gray-900"
                    >
                      Dashboard
                    </Link>
                  ) : (
                    <>
                      <Link
                        to="/login"
                        className="xl:text-base md:text-sm font-normal font-inter text-black hover:text-gray-900"
                      >
                        Login
                      </Link>
                      <Link
                        to="/signup"
                        className="xl:text-base md:text-sm font-normal font-inter text-black hover:text-gray-900"
                      >
                        Signup
                      </Link>
                    </>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
