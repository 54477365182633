import React, { useState, useEffect } from "react";
import search from "../../assests/searchIcon.svg";
import profile from "../../assests/profile.svg";
import { allUserBirthDays } from "../../Api";
import { format, startOfWeek, endOfWeek } from "date-fns";
import { enUS } from "date-fns/locale";

export default function TeamBirthDay() {
  const [value, setValue] = useState("month");
  const [event, setEvent] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line
  }, [event]);
  const getEvents = async () => {
    const res = await allUserBirthDays(query);
    setEvent(res?.data?.birthday);
  };
  const currentMonth = new Date().getMonth();

  const getMonthData = (value) => {
    return parseInt(value?.DOB?.split("-")[1], 10) === currentMonth + 1;
  };
  const filteredDOB = event?.filter(
    (user) => user.DOB && user.DOB.trim() !== ""
  );
  const filteredMonth = filteredDOB?.filter(getMonthData);

  const getMonth = (val) => {
    const year = val?.split("-")[0];
    const month = val?.split("-")[1].split("0")[1];
    const date = val?.split("-")[2].split("T")[0];
    const mmm = format(new Date(), "MMMM", { locale: enUS });
    if (month === (currentMonth + 1).toString()) {
      return (
        <span>
          {date}-{mmm}-{year}
        </span>
      );
    }
  };

  const getWeek = (val) => {
    const year = val?.split("-")[0];
    const month = val?.split("-")[1].split("0")[1];
    const date = val?.split("-")[2].split("T")[0];

    const mmm = format(new Date(), "MMMM", { locale: enUS });
    if (month === (currentMonth + 1).toString()) {
      return (
        <span>
          {date}-{mmm}-{year}
        </span>
      );
    }
  };

  const isBirthdayInSpecifiedWeek = (weekStartStr, weekEndStr, dobStr) => {
    const weekStart = new Date(weekStartStr);
    const weekEnd = new Date(weekEndStr);

    // Parse the DOB string and adjust its year to match the week's year
    const [, month, day] = dobStr.split("-").map(Number);

    let dobDate = new Date(weekStart.getFullYear(), month - 1, day);

    return dobDate >= weekStart && dobDate <= weekEnd;
  };

  const getBirthdayInWeeks = filteredMonth.filter((obj, i) => {
    const dobTest = obj.DOB;
    const date = new Date();
    const weekStartTest = startOfWeek(date);
    const weekEndTest = endOfWeek(date);
    return isBirthdayInSpecifiedWeek(weekStartTest, weekEndTest, dobTest);
  });

  return (
    <div className="xl:w-2/4 md:w-2/4 w-full bg-white rounded-lg pt-2  shadow-xl mt-10 flex items-center  flex-col">
      <div className="w-full px-4">
        <div className="w-full">
          <h1 className="font-semibold text-base ">Team Members Birthdays </h1>
        </div>
        <div className="w-full h-12 border  border-[#A1A1A1] flex items-center rounded-lg mt-4">
          <img src={search} alt="search" className="w-5 h-5 ml-3" />
          <input
            placeholder="Search User by name or email"
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
            className="placeholder-[#A1A1A1] text-sm w-full pl-2 outline-none text-[#A1A1A1]"
          />
        </div>
        <div className="w-full flex mt-4">
          <button
            onClick={() => setValue("month")}
            className={`w-32 h-8 ${
              value === "month" ? "bg-[#01464F] text-white" : ""
            }   rounded-sm`}
          >
            This Month
          </button>

          <button
            onClick={() => setValue("week")}
            className={`w-32 ml-4 h-8 ${
              value === "week" ? "bg-[#01464F] text-white" : ""
            }   rounded-sm`}
          >
            This Week
          </button>
        </div>
        <div className="py-5 h-[40vh] overflow-y-scroll">
          {value === "month" ? (
            <div>
              {filteredMonth?.map((obj, i) => (
                <div
                  key={obj?._id}
                  className=" w-full flex border-b  h-24 py-3 px-5  "
                >
                  <div className="lg:w-1/4 w-16 rounded-full">
                    <img
                      src={profile}
                      className="h-full w-full"
                      alt="profile"
                    />
                  </div>
                  <div className="w-3/4 flex flex-col justify-center pl-3">
                    <h1 className="text-sm font-medium">{obj?.name}</h1>
                    <p className="font-normal text-sm uppercase text-[#929497]">
                      {obj?.role}
                    </p>
                    <p className="font-normal text-sm text-[#929497]">
                      D-O-B
                      <span className="text-black ml-2 font-medium">
                        {getMonth(obj?.DOB)}
                      </span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {getBirthdayInWeeks?.map((obj, i) => (
                <div
                  key={obj?._id}
                  className=" w-full flex border-b  h-24 py-3 px-5  "
                >
                  <div className="lg:w-1/4 w-16 rounded-full">
                    <img
                      src={profile}
                      className="h-full w-full"
                      alt="profile"
                    />
                  </div>
                  <div className="w-3/4 flex flex-col justify-center pl-3">
                    <h1 className="text-sm font-medium">{obj?.name}</h1>
                    <p className="font-normal text-sm uppercase text-[#929497]">
                      {obj?.role}
                    </p>
                    <p className="font-normal text-sm text-[#929497]">
                      D-O-B
                      <span className="text-black ml-2 font-medium">
                        {getWeek(obj?.DOB)}
                      </span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
