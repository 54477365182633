import React from "react";
import gruddge from "../../assests/guddgeLogo.svg";
import cyber from "../../assests/Cyber.svg";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { forgetPassword } from "../../Api";
import Swal from "sweetalert2";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address format")
    .required("Please enter your email"),
});

export default function ForgetPassword() {
  return (
    <div className="w-full flex justify-center items-center bg-[#F5F5F5] p-5 ">
      <div className="max-w-[700px] bg-white rounded-2xl flex flex-col items-center justify-center py-12 lg:px-16 px-8   shadow-lg">
        <div className="max-w-[550px]">
          <div>
            <img src={gruddge} alt="logo" className="h-10" />
          </div>
          <div className="w-full flex justify-center items-center my-4">
            <img src={cyber} alt="CyberSecurity" />
          </div>
          <div className="mt-4 text-[14px] text-[#666666] ">
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={loginSchema}
              onSubmit={async (values) => {
                try {
                  const res = await forgetPassword(values);
                  if (res?.data?.success) {
                    Swal.fire({
                      width: "20em",
                      height: "20em",
                      position: "center",
                      icon: "success",
                      title: `${res?.data?.message}`,
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                } catch (error) {
                  Swal.fire({
                    width: "20em",
                    height: "20em",
                    title: `${error?.response?.data.message}`,
                    showClass: {
                      popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutUp",
                    },
                  });
                }
              }}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <div>
                    <h1 className="font-medium text-base">
                      Please Enter Your Email
                    </h1>
                  </div>
                  <div className="flex flex-col mt-3">
                    <label htmlFor="email">Email</label>
                    <Field
                      name="email"
                      placeholder="someone@example.com"
                      className={`border-2 border-[#818181] rounded mt-1 h-[35px] pl-2 outline-none  ${
                        touched.email && errors.email ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="text-red-700 font-normal font-base text-left"
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="font-normal w-full bg-black h-[35px] mt-3 text-white rounded"
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
