import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { format } from "date-fns";
import {
  allInvoiceReports,
  deleteInvoiceById,
  allClients,
  changeStatus,
} from "../../Api";
import DataTable from "./DataTable";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import * as XLSX from "xlsx";

export default function TimeSheetReports() {
  const [data, setData] = useState();
  const [flyer, setFlyer] = useState(false);
  const [flyer2, setFlyer2] = useState(false);
  const [flyer3, setFlyer3] = useState(false);
  const [clients, setClients] = useState();
  const [query, setQuery] = useState({
    client: "",
    status: "",
    startDate: "",
    endDate: "",
  });

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    getInvoiceReport();
    allClient();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getInvoiceReport();
    // eslint-disable-next-line
  }, [data, query]);

  const getInvoiceReport = async () => {
    try {
      const res = await allInvoiceReports(query);

      if (res?.status === 200) {
        setData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const allClient = async () => {
    try {
      const res = await allClients();

      if (res?.status === 200) {
        setClients(res?.data?.clients);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteInvoiceById(id);
    if (res) {
      Swal.fire({
        width: "20em",
        height: "20em",
        position: "center",
        icon: "success",
        title: "Deleted successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        width: "20em",
        height: "20em",
        position: "center",
        icon: "success",
        title: "Something wents wrong?",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const statusValue = ["paid", "unpaid"];

  const handlePaid = async (value) => {
    const res = await changeStatus(value);
    if (res?.data?.success) {
      Swal.fire({
        width: "20em",
        height: "20em",
        position: "center",
        icon: "success",
        title: `${res?.data?.message}`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const handleRange = (value) => {
    const isSelected = value[0].startDate !== value[0].endDate;
    if (isSelected) {
      const start = format(new Date(value[0].startDate), "MM/dd/yyyy");
      const end = format(new Date(value[0].endDate), "MM/dd/yyyy");
      setQuery({ ...query, startDate: start, endDate: end });
    }
  };

  const saveAsExcelFile = () => {
    const transformedData = data?.map((item) => {
      return {
        contractorName: item.name,
        clientName: item.clientName,
        createdOn: item.sharedDate,
        clientRate: item.clientRate,
        total: item.total,
        timesheetName: item.timeSheetName,
        status: item.status,
      };
    });
    // Convert JSON to 2D array
    const ws_data = [
      Object.keys(transformedData[0]),
      ...transformedData?.map((obj) => Object.values(obj)),
    ];

    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // save to file
    XLSX.writeFile(
      wb,
      `Invoice_Reports_${format(new Date(), "MM/dd/yyyy")}.xlsx`
    );
  };
  return (
    <div className="space-y-10 w-full">
      <div className="flex gap-5 md:flex-row flex-col">
        <div className="w-full">
          <h1 className={` font-medium text-base space-y-3`}>Status</h1>
          <div className="relative w-full border">
            <button
              id="dropdownDelayButton"
              data-dropdown-toggle="dropdownDelay"
              data-dropdown-delay="500"
              data-dropdown-trigger="hover"
              className="text-black w-full justify-between outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
              type="button"
              onClick={() => {
                setFlyer2(!flyer2);
                setFlyer(false);
                setFlyer3(false);
              }}
            >
              <p className="text-gray-400">
                {query.status ? query.status : "Select"}
              </p>
              <svg
                className="w-4 h-4 ml-2"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            <div
              id="dropdownDelay"
              className={` ${flyer2 ? "block" : "hidden"}               
              z-40 absolute w-full border bg-white divide-y divide-gray-100 shadow dark:bg-gray-700`}
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDelayButton"
              >
                {statusValue?.map((obj, i) => (
                  <li
                    key={i}
                    onClick={() => {
                      setQuery({ ...query, status: obj });
                      setFlyer2(false);
                    }}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    {obj}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full relative">
          <h1 className={` font-medium text-base space-y-3`}>Created on</h1>
          <div className="relative">
            <div
              className={`flex bg-white absolute items-center h-11 w-full border `}
            >
              <button
                onClick={() => {
                  setFlyer(!flyer);
                  setFlyer2(false);
                  setFlyer3(false);
                }}
                className="text-sm w-full text-left lg:px-5 px-3 h-full text-gray-400 font-medium"
              >
                Select Date Range
              </button>
            </div>
            <div
              className={`absolute z-10 top-10 h-11 w-full border ${
                flyer ? "block" : "hidden"
              }`}
            >
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
                // showSelectionPreview={true}
                onRangeFocusChange={() => handleRange(state)}
              />
            </div>
          </div>
        </div>
        <div className="w-full mt-10 md:mt-0">
          <h1 className={`font-medium text-base space-y-3`}>Client</h1>
          <div className="relative w-full border">
            <button
              id="dropdownDelayButton"
              data-dropdown-toggle="dropdownDelay"
              data-dropdown-delay="500"
              data-dropdown-trigger="hover"
              className="text-black w-full justify-between outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
              type="button"
              onClick={() => {
                setFlyer3(!flyer3);
                setFlyer(false);
                setFlyer2(false);
              }}
            >
              <p className="text-gray-400">
                {query.client ? query.client : "Select"}
              </p>
              <svg
                className="w-4 h-4 ml-2"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            <div
              id="dropdownDelay"
              className={` ${
                flyer3 ? "block" : "hidden"
              } z-40 absolute w-full border bg-white divide-y divide-gray-100 shadow dark:bg-gray-700`}
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDelayButton"
              >
                {clients?.map((obj, i) => (
                  <li
                    key={i}
                    onClick={() => {
                      setQuery({ ...query, client: obj.name });
                      setFlyer3(false);
                    }}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    {obj.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="max-h-screen lg: px-2 overflow-x-hidden overflow-y-auto">
        <DataTable
          data={data}
          handleDelete={handleDelete}
          handlePaid={handlePaid}
        />
      </div>
      <div className="flex w-full lg:justify-start justify-center">
        <button
          onClick={saveAsExcelFile}
          className="px-8 rounded-lg border-none outline-none text-white font-semibold bg-green-400 py-2"
        >
          Save as .xlsx
        </button>
      </div>
    </div>
  );
}
